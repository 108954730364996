import type { FormatMessage } from '@aurora/shared-types/texts';
import type { FileRejection } from 'react-dropzone-kh';
import type { Editor } from 'tinymce';
import { ToastAlertVariant, ToastVariant } from '../../components/common/ToastAlert/enums';
import type ToastProps from '../../components/common/ToastAlert/ToastAlertProps';

/**
 * An object that stores hex data and image type.
 */
interface ImageHexData {
  /**
   * The Hex value of the image.
   */
  hex: string;
  /**
   * The type of the image.
   */
  type: string;
}

/**
 * An object that stores Clipboard content and its type.
 */
export interface ContentInfo {
  /**
   * The Clipboard content.
   */
  content: string;
  /**
   * Whether the content is rtf (MS word).
   */
  isRtfContent: boolean;
  /**
   * Whether the content has html content.
   */
  isHtml: boolean;
}

/**
 * Returns the dataTransfer items from clipboard data.
 *
 * @param dataTransfer
 */
export function getDataTransferItems(dataTransfer: DataTransfer): Record<string, string> {
  const items: Record<string, string> = {};
  const mceInternalUrlPrefix = 'data:text/mce-internal,';
  if (dataTransfer) {
    if (dataTransfer.getData) {
      const legacyText = dataTransfer.getData('Text');
      if (legacyText && legacyText.length > 0 && !legacyText.includes(mceInternalUrlPrefix)) {
        items['text/plain'] = legacyText;
      }
    }
    if (dataTransfer.types) {
      for (let i = 0; i < dataTransfer.types.length; i++) {
        const contentType = dataTransfer.types[i];
        try {
          items[contentType] = dataTransfer.getData(contentType);
        } catch {
          items[contentType] = '';
        }
      }
    }
  }
  return items;
}

/**
 * Returns the dataTransfer items from clipboard data.
 *
 * @param editor
 * @param clipboardEvent
 */
export function getClipboardContent(
  editor,
  clipboardEvent: ClipboardEvent
): Record<string, string> {
  return getDataTransferItems(clipboardEvent.clipboardData ?? editor.getDoc().dataTransfer);
}

/**
 * Returns an arrayBuffer out of hex string content.
 *
 * @param hexString
 */
export function getArrayBufferFromHex(hexString: string): Uint8Array {
  const hexSize = hexString.length;
  const arrayBuffer = new Uint8Array(hexSize / 2);
  for (let i = 0; i < hexSize; i += 2) {
    arrayBuffer[i / 2] = Number.parseInt(hexString.slice(i, i + 2), 16);
  }
  return arrayBuffer;
}

/**
 * Returns the file extension from the given type.
 *
 * @param type
 */
export function getFileExtensionFromType(type: string): string {
  const regexp = /^image\/([a-z]+)(;.*)?$/;
  const matched = type.match(regexp);
  if (matched !== null) {
    return matched[1];
  }
  return null;
}

/**
 * Returns the image files from word rtfContent using regex operation.
 *
 * @param rtfContent
 */
export function getImagesHexFromRtf(rtfContent: string): ImageHexData[] {
  const returnValue = [];
  const rePictureHeader =
      /{\\pict[\S\s]+?\\bliptag-?\d+(\\blipupi-?\d+)?({\\\*\\blipuid\s?[\dA-Fa-f]+)?[\s}]*?/,
    rePicture = new RegExp('(?:(' + rePictureHeader.source + '))([\\da-fA-F\\s]+)\\}', 'g');
  let imageType;
  const wholeImages = rtfContent.match(rePicture);
  if (!wholeImages) {
    return returnValue;
  }

  for (const wholeImage of wholeImages) {
    if (rePictureHeader.test(wholeImage)) {
      if (wholeImage.includes('\\pngblip')) {
        imageType = 'image/png';
      } else if (wholeImage.includes('\\jpegblip')) {
        imageType = 'image/jpeg';
      } else {
        continue;
      }

      returnValue.push({
        hex: imageType
          ? wholeImage.replace(rePictureHeader, '').replaceAll(/[^\dA-Fa-f]/g, '')
          : null,
        type: imageType
      });
    }
  }
  return returnValue;
}

/**
 * Returns than unique file name for the images based on upload time.
 *
 * @param index
 * @param extension
 */
export function buildFileName(index: number, extension: string): string {
  return `clipboard_image-${index}-${Date.now()}.${extension}`;
}

/**
 * Returns the image file map from word rtfContent.
 *
 * @param baseIndex to keep sync between pre process and post process handlers
 * @param rtfContent
 */
export function getImagesFromRtfContent(rtfContent: string, baseIndex: number): File[] {
  const rtfImages = getImagesHexFromRtf(rtfContent),
    imageFilesMap = [];
  rtfImages.forEach((image, index) => {
    const arrayBuffer = getArrayBufferFromHex(image.hex);
    const blob = new Blob([arrayBuffer], { type: image.type });
    const file = new File(
      [blob],
      buildFileName(index + baseIndex, getFileExtensionFromType(image.type)),
      { type: image.type }
    );
    imageFilesMap.push(file);
  });
  return imageFilesMap;
}

/**
 * This uses the regex to extract all the img tags from the HTML content
 * and extracts the URLs from the src attribute of each img tag and returns an array of URLs.
 * @param html
 */
function getBlobUrlsFromHtml(html: string): RegExpMatchArray {
  const imgTags = html.match(/<img[^>]+src="([^"]+)[^>]+/g);
  if (imgTags) {
    return <RegExpMatchArray>imgTags.map(tag => tag.match(/src="([^"]+)/)[1]);
  }
  return <RegExpMatchArray>(<unknown>[]);
}

/**
 * The fetch method is used to retrieve the image data from the specified URL.
 * The response.blob() method is used to convert the image data to a Blob object,
 * Then returns a file out of the blob.
 * @param url
 * @param baseIndex to maintain sync between pre process and post process handlers.
 * @param addToast
 * @param localFormatMessage
 */
async function handleBlobUrlsToImages(
  url: string,
  baseIndex: number,
  addToast: (toast: ToastProps) => void,
  localFormatMessage: FormatMessage
): Promise<File> {
  try {
    const response = await fetch(url);
    const fileType = response.headers.get('content-type');
    const fileExtension = getFileExtensionFromType(fileType);
    const blob = await response.blob();
    return new File([blob], buildFileName(baseIndex, fileExtension), { type: fileType });
  } catch {
    const toastProps: ToastProps = {
      toastVariant: ToastVariant.BANNER,
      alertVariant: ToastAlertVariant.DANGER,
      title: localFormatMessage('error.MediaDownloadFailFromSourceUrl.title'),
      message: localFormatMessage('error.MediaDownloadFailFromSourceUrl.description'),
      id: url + Math.random()
    };
    addToast(toastProps);
    return null;
  }
}

/**
 * This converts an array of URLs and returns a promise that resolves to an array of image files.
 * @param content
 * @param baseIndex to maintain sync between pre-process and post-process handlers.
 * @param addToast
 * @param localFormatMessage
 */
export async function getImagesFromHtmlContent(
  content: string,
  baseIndex: number,
  addToast: (toast: ToastProps) => void,
  localFormatMessage: FormatMessage
): Promise<PromiseSettledResult<File>[]> {
  const blobUrls = getBlobUrlsFromHtml(content);
  return await Promise.allSettled(
    blobUrls.map((url, index) =>
      handleBlobUrlsToImages(url, baseIndex + index, addToast, localFormatMessage)
    )
  );
}

/**
 * Registers paste into the RTE
 *
 * @param editor
 * @param getFileRejections
 * @param onPaste
 * @param clipBoardContentInfo to update clipboard content info to RTE.
 */
export default function registerEditorPaste(
  editor: Editor,
  getFileRejections: (files: File[]) => FileRejection[],
  onPaste: (acceptedFiles, fileRejections) => void,
  clipBoardContentInfo: (contentInfo) => void
) {
  editor.on('paste', async event => {
    const { files, types } = event.clipboardData;
    const isRtfContent = types.includes('text/rtf');
    const isHtml = types.includes('text/html');
    if (files?.length === 1 && !isRtfContent && !isHtml) {
      event.preventDefault();
      const filesToValidate: File[] = [...files];
      const fileRejections = getFileRejections(filesToValidate);
      const acceptedFiles = fileRejections.length === 0 ? filesToValidate : [];
      onPaste(acceptedFiles, fileRejections);
    } else if (isRtfContent || isHtml) {
      const clipboardContent = getClipboardContent(editor, event);
      const content = clipboardContent['text/rtf'] || clipboardContent['text/html'];
      const contentInfo: ContentInfo = {
        content,
        isRtfContent,
        isHtml
      };
      clipBoardContentInfo(contentInfo);
    }
  });
}
