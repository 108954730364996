import { ToastAlertVariant, ToastVariant } from '../../components/common/ToastAlert/enums';
import type { ImageUploadProperties } from '../../components/images/useImageUploadProperties';
import type { VideoUploadProperties } from '../../components/videos/useVideoUploadProperties';
import type { FileRejection } from 'react-dropzone-kh';
import type ToastProps from '../../components/common/ToastAlert/ToastAlertProps';
import FileOption from './FileOption';
import type { FormattedMessageWithValues } from '../../components/i18n/types';

interface ValidateMediaResult {
  /**
   * The accepted image files.
   */
  acceptedImages: File[];
  /**
   * The accepted video files.
   */
  acceptedVideos: File[];
  /**
   * The toast props for the rejected media files.
   */
  toastProps: ToastProps[];
}

export function validateMedia(
  acceptedFiles: File[],
  rejectedFiles: FileRejection[],
  imageUploadProperties: ImageUploadProperties,
  videoUploadProperties: VideoUploadProperties,
  allowedImageMimeTypes: string[],
  allowedImageFileTypesFormatted: string,
  localFormatMessage
): ValidateMediaResult {
  const {
    maxFileSize: maxImageSize,
    maxFileSizeFormatted: maxImageSizeFormatted,
    maxFilesPerUpload: maxImagesPerUpload
  } = imageUploadProperties;
  const {
    maxFileSize: maxVideoSize,
    maxFileSizeFormatted: maxVideoSizeFormatted,
    allowedMimeTypes: allowedVideoMimeTypes,
    allowedFileTypes: allowedVideoFileTypes,
    allowedFileTypesFormatted: allowedVideoFileTypesFormatted
  } = videoUploadProperties;
  let acceptedImages: File[] = [];
  const acceptedVideos: File[] = [];
  const errors: FormattedMessageWithValues[] = [];
  const allowedMediaFileTypesFormatted: string =
    allowedImageFileTypesFormatted + allowedVideoFileTypesFormatted;
  acceptedFiles.forEach(file => {
    const { name: fileName, type, size } = file;
    if (
      !allowedMediaFileTypesFormatted
        .toLowerCase()
        .includes(fileName.split('.').pop().toLowerCase())
    ) {
      errors.push({
        id: 'errorBadExtension',
        values: { fileName }
      });
    } else if (allowedImageMimeTypes.includes(type)) {
      if (size > maxImageSize) {
        errors.push({
          id: 'errorTooLarge',
          values: { fileName, maxFileSize: maxImageSizeFormatted }
        });
      } else {
        acceptedImages.push(file);
      }
    } else if (
      allowedVideoMimeTypes.includes(type) ||
      (type == '' && allowedVideoFileTypes.includes('.' + fileName.split('.').pop()))
    ) {
      if (size > maxVideoSize) {
        errors.push({
          id: 'errorTooLarge',
          values: { fileName, maxFileSize: maxVideoSizeFormatted }
        });
      } else {
        acceptedVideos.push(file);
      }
    }
  });

  rejectedFiles.forEach(rejectedItem => {
    const {
      file: { name: fileName }
    } = rejectedItem;
    errors.push({ id: 'errorBadExtension', values: { fileName } });
  });

  if (acceptedImages.length > maxImagesPerUpload) {
    acceptedImages = acceptedImages.slice(0, maxImagesPerUpload);
    errors.push({ id: 'errorTooMany', values: { maxFilesPerUpload: maxImagesPerUpload } });
  }

  const toastPropsList: ToastProps[] = [];
  errors.forEach(error => {
    const { id, values } = error;
    const { fileName } = values;
    const toastProps: ToastProps = {
      toastVariant: ToastVariant.BANNER,
      alertVariant: ToastAlertVariant.DANGER,
      title: localFormatMessage(`${id}.title`),
      message: localFormatMessage(`${id}.description`, values),
      id: `${fileName}-${id}`
    };
    toastPropsList.push(toastProps);
  });

  return {
    acceptedImages,
    acceptedVideos,
    toastProps: toastPropsList
  };
}
/**
 * Gets all the errors on the drop of rejected files in the drop zone.
 *
 * @param fileRejections
 * @param maxFileSizeFormatted
 * @param allowedMimeTypesFormatted
 */
export default function getErrorsFromFileRejections(
  fileRejections: FileRejection[],
  maxFileSizeFormatted: string,
  allowedMimeTypesFormatted: string
) {
  const options: FileOption[] = [];
  fileRejections?.forEach(fileRejection => {
    const {
      file: { name: rejectedFileName, size, type },
      errors: dropErrors
    } = fileRejection;
    const option = new FileOption(rejectedFileName, size, type);
    const uploadErrors: FormattedMessageWithValues[] = [];

    dropErrors.forEach(dropError => {
      switch (dropError.code) {
        case 'file-too-large': {
          uploadErrors.push({
            id: 'errorTooLarge',
            values: {
              maxFileSizeFormatted
            }
          });
          break;
        }
        case 'file-too-small': {
          uploadErrors.push({
            id: 'errorFileEmpty'
          });
          break;
        }
        case 'too-many-files': {
          break;
        }
        case 'file-invalid-type': {
          uploadErrors.push({
            id: 'errorBadExtension',
            values: {
              extensions: allowedMimeTypesFormatted
            }
          });
          break;
        }
        default: {
          break;
        }
      }
      if (uploadErrors.length > 0) {
        option.setErrors(uploadErrors);
      }
    });
    options.push(option);
  });

  return options;
}
