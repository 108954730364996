import type { Editor } from 'tinymce';
import { isMentionsElement } from './EditorMentionsHelper';
import { Actions } from './TinyMceInternalHelper';
import { getAnchorWithinFigure, isImageFigure } from './EditorMediaHelper';
import { isVideoFigure } from './EditorVideoHelper';

/**
 * Information about a link captured in the TinyMce editor.
 */
export interface LinkInfo {
  /**
   * The href of the link.
   */
  href?: string;
  /**
   * The text of the link.
   */
  text?: string;
  /**
   * The anchor of the link.
   */
  anchor?: string;
}

export interface UrlMatchResult {
  /**
   * The text to display.
   */
  text: string;
  /**
   * The type of the url object.
   */
  type?: string;
}

/**
 * Partial copies of the TinyMce Link Plugin internal APIs needed to accomplish the desired behaviors
 * for our use cases. Current use cases include:
 *
 * <ul>
 *   <li>Custom link modal that matches our Design Language System and only
 *   contains url and text form fields. The core TinyMce link modal contains
 *   more fields that are not easily removable by JS or CSS without impacting
 *   all modals implemented by TinyMce.</li>
 * </ul>
 *
 * Taken from `tinymce/src/plugins/link/**`.
 *
 * @author Adam Ayres
 */

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
function getAnchorElement(editor: Editor, selectedElm?: Element): HTMLAnchorElement | null {
  const localSelectedElm = selectedElm || editor.selection.getNode();
  if (isImageFigure(localSelectedElm)) {
    // for an image contained in a figure we look for a link inside the selected element
    const element = getAnchorWithinFigure(localSelectedElm);
    return element || null;
  } else {
    return editor.dom.getParent(localSelectedElm, 'a[href]') as HTMLAnchorElement;
  }
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
export function isAnchor(elm: Node): elm is HTMLAnchorElement {
  return elm && elm.nodeName.toLowerCase() === 'a';
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
function collectNodesInRange<T extends Node>(rng: Range, predicate: (node) => node is T): T[] {
  if (rng.collapsed) {
    return [];
  } else {
    const contents = rng.cloneContents();
    const walker = new window.tinymce.dom.TreeWalker(contents.firstChild, contents);
    const elements: T[] = [];
    let current: Node = contents.firstChild;
    do {
      if (predicate(current)) {
        elements.push(current);
      }
      // eslint-disable-next-line no-cond-assign
    } while ((current = walker.next()));
    return elements;
  }
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
function trimCaretContainers(text: string): string {
  return text.replaceAll('﻿', '');
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
function isOnlyTextSelected(editor: Editor): boolean {
  // Allow anchor and inline text elements to be in the selection but nothing else
  const inlineTextElements = editor.schema.getTextInlineElements();
  const isElement = (elm: Node): elm is Element =>
    elm.nodeType === 1 &&
    !isAnchor(elm) &&
    Object.prototype.hasOwnProperty.call(inlineTextElements, elm.nodeName.toLowerCase());

  // Collect all non inline text elements in the range and make sure no elements were found
  const elements = collectNodesInRange(editor.selection.getRng(), isElement);
  return elements.length === 0;
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
function getAnchorText(selection, anchorElm: HTMLAnchorElement): string {
  const text = anchorElm ? anchorElm.textContent : selection.getContent({ format: 'text' });
  return trimCaretContainers(text);
}

// Copied from tinymce/src/plugins/link/main/ts/ui/DialogInfo.ts
function extractFromAnchor(editor: Editor, anchor: HTMLAnchorElement): LinkInfo {
  const onlyText = isOnlyTextSelected(editor);
  const text: string = onlyText ? getAnchorText(editor.selection, anchor) : '';
  const href: string = anchor ? editor.dom.getAttrib(anchor, 'href') : '';

  return {
    href,
    text
  };
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
function linkImageFigure(editor: Editor, fig: Element, attrs: Record<string, string>) {
  const [img] = editor.dom.select('img', fig);
  if (img) {
    const a = editor.dom.create('a', attrs);
    img.parentNode.insertBefore(a, img);
    a.append(img);
  }
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
function unlinkImageFigure(editor: Editor, fig: Element) {
  const [img] = editor.dom.select('img', fig);
  if (img) {
    const [a] = editor.dom.getParents(img, 'a[href]', fig);
    if (a) {
      a.parentNode.insertBefore(img, a);
      editor.dom.remove(a);
    }
  }
}

/**
 * Get attributes for anchor when its internal/external/in-page link
 *
 * @param isInternal is internal url
 * @param href url to set on anchor
 * @param linkCssClass class to set on anchor
 * @param text text to set on anchor
 */
function getLinkAttrubutes(isInternal: boolean, href: string, linkCssClass: string, text: string) {
  return isInternal
    ? {
        href,
        class: linkCssClass,
        'data-lia-auto-title': text,
        'data-lia-auto-title-active': '0'
      }
    : href.startsWith('#')
    ? { href, target: '_self' }
    : { href, class: linkCssClass };
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
function updateLink(
  editor: Editor,
  selectedElm: Element,
  anchorElm: HTMLAnchorElement,
  text: string,
  href: string,
  linkCssClass: string,
  isInternal: boolean
): void {
  if (isImageFigure(selectedElm)) {
    editor.dom.setAttribs(anchorElm, { href });
    editor.selection.select(selectedElm);
  } else {
    // eslint-disable-next-line no-param-reassign
    const linkAttrs = getLinkAttrubutes(isInternal, href, linkCssClass, text);
    anchorElm.textContent = text;
    editor.dom.setAttribs(anchorElm, linkAttrs);
    editor.selection.select(anchorElm);
  }
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
function createLink(
  editor: Editor,
  selectedElm: Element,
  text: string,
  href: string,
  linkCssClass: string,
  isInternal: boolean
): void {
  if (isImageFigure(selectedElm)) {
    const linkAttrs = { href };
    linkImageFigure(editor, selectedElm, linkAttrs);
    editor.selection.select(selectedElm);
  } else {
    // TODO: figure out why the form changes caused text to be empty (something related to field watchers)
    const linkText = text === '' ? href : text;
    const linkAttrs = getLinkAttrubutes(isInternal, href, linkCssClass, text);
    const selectedText = editor.selection.getContent({ format: 'text' });
    const trimmedSelectedText = trimCaretContainers(selectedText);
    if (trimmedSelectedText !== linkText) {
      editor.insertContent(editor.dom.createHTML('a', linkAttrs, editor.dom.encode(linkText)));
    } else {
      editor.execCommand('mceInsertLink', false, linkAttrs);
    }
  }
}

// Copied from tinymce/src/plugins/link/main/ts/api/Types.ts
export enum AssumeExternalTargets {
  OFF,
  WARN, // not supported
  ALWAYS_HTTP = 'http',
  ALWAYS_HTTPS = 'https'
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
function hasProtocol(url: string): boolean {
  return /^\w+:/i.test(url);
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
const handleExternalTargets = (
  href: string,
  assumeExternalTargets: AssumeExternalTargets
): string => {
  if (
    (assumeExternalTargets === AssumeExternalTargets.ALWAYS_HTTP ||
      assumeExternalTargets === AssumeExternalTargets.ALWAYS_HTTPS) &&
    !hasProtocol(href)
  ) {
    return `${assumeExternalTargets}://${href}`;
  }
  return href;
};

/**
 * Returns the css class for internal url based on url object type..
 *
 * @param type The type of the url object.
 */
export function getInternalLinkCssClass(type: string): string {
  let linkCssClasses;
  if (type) {
    linkCssClasses = 'lia-internal-link lia-internal-url';
    if (type === 'user') {
      linkCssClasses += ` lia-internal-url-${type}`;
    } else {
      linkCssClasses += ` lia-internal-url-content-type-${type}`;
    }
  } else {
    linkCssClasses = 'lia-internal-link';
  }
  return linkCssClasses;
}

/**
 * Returns the title of the url page using fetch api call.
 *
 * @param url the url entered.
 */
export async function getPageTitleFromUrl(url: string): Promise<string | null> {
  try {
    const data = await fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Accept: 'text/html',
        'Content-Type': 'text/html'
      }
    });
    const dataText = await data.text();
    const parser = new DOMParser();
    const dataDocument = parser.parseFromString(dataText, 'text/html');
    return dataDocument.querySelector('title').textContent;
  } catch {
    return null;
  }
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
async function linkDomMutation(
  editor: Editor,
  text: string,
  href: string,
  selectedElm: Element,
  onMatch?: (url: string) => Promise<UrlMatchResult>
): Promise<void> {
  const anchorElm = getAnchorElement(editor, selectedElm);

  const externalTargets: AssumeExternalTargets = editor.getParam(
    'link_assume_external_targets',
    AssumeExternalTargets.OFF
  );
  const adjustedHref = href.startsWith('#') ? href : handleExternalTargets(href, externalTargets);
  let isInternal = false;

  let linkCssClasses = 'lia-external-url';
  let displayText = text;

  if (onMatch) {
    const data = await onMatch(adjustedHref);
    if (data.text) {
      isInternal = true;
      linkCssClasses = getInternalLinkCssClass(data.type);

      if (!displayText || displayText === href) {
        displayText = data.text;
      }
    }
  }

  editor.undoManager.transact(() => {
    if (anchorElm) {
      editor.focus();
      updateLink(
        editor,
        selectedElm,
        anchorElm,
        displayText,
        adjustedHref,
        linkCssClasses,
        isInternal
      );
    } else {
      createLink(editor, selectedElm, displayText, adjustedHref, linkCssClasses, isInternal);
    }
  });
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
function unlinkSelection(editor: Editor): void {
  const { dom, selection } = editor;
  const bookmark = selection.getBookmark();
  const rng = selection.getRng().cloneRange();
  // Extend the selection out to the entire anchor element
  const startAnchorElm = dom.getParent(rng.startContainer, 'a[href]', editor.getBody());
  const endAnchorElm = dom.getParent(rng.endContainer, 'a[href]', editor.getBody());
  if (startAnchorElm) {
    rng.setStartBefore(startAnchorElm);
  }
  if (endAnchorElm) {
    rng.setEndAfter(endAnchorElm);
  }
  selection.setRng(rng);

  // Remove the link
  editor.execCommand('unlink');
  selection.moveToBookmark(bookmark);
}

// Copied from tinymce/src/plugins/link/main/ts/core/Utils.ts
function unlinkDomMutation(editor: Editor, selectedElm: Element): void {
  editor.undoManager.transact(() => {
    if (isImageFigure(selectedElm)) {
      unlinkImageFigure(editor, selectedElm);
    } else {
      unlinkSelection(editor);
    }
    editor.focus();
  });
}

/**
 * Returns true if the element is pure link and not mentions element and media element
 *
 * @param editor the Editor
 * @param selectedElm
 */
const isLinkElement = (editor: Editor, selectedElm: Element) => {
  const anchorElement = getAnchorElement(editor, selectedElm);
  return (
    anchorElement !== null &&
    !isMentionsElement(selectedElm as HTMLElement) &&
    !isImageFigure(selectedElm) &&
    !isVideoFigure(selectedElm)
  );
};

// Copied from tinymce/src/plugins/link/main/ts/core/Actions.ts
const toggleActiveState = (editor: Editor) => api => {
  return Actions.toggleState(editor, () => {
    const node = editor.selection.getNode();
    api.setActive(!editor.mode.isReadOnly() && isLinkElement(editor, node));
    const useActiveState =
      isMentionsElement(node as HTMLElement) || isImageFigure(node) || isVideoFigure(node);
    api.setEnabled(!useActiveState);
  });
};

// Copied from tinymce/src/plugins/link/main/ts/core/OpenUrl.ts
const openLink = (url: string): void => {
  const link = document.createElement('a');
  link.target = '_blank';
  link.href = url;
  link.rel = 'noreferrer noopener';

  const event = document.createEvent('MouseEvents');
  event.initMouseEvent(
    'click',
    true,
    true,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  );

  document.body.append(link);
  link.dispatchEvent(event);
  link.remove();
};

// Copied from tinymce/src/plugins/link/main/ts/core/Action.ts
const openLinkInNewTab = (editor: Editor, a: HTMLAnchorElement): void => {
  if (a) {
    const href = a.dataset.mceHref || a.getAttribute('href');
    if (href.startsWith('#')) {
      const targetElement = editor.dom.select(href);
      if (targetElement.length > 0) {
        editor.selection.scrollIntoView(targetElement[0], true);
      }
    } else {
      openLink(a.href);
    }
  }
};

export {
  getAnchorElement,
  extractFromAnchor,
  isLinkElement,
  linkDomMutation,
  unlinkDomMutation,
  unlinkSelection,
  toggleActiveState,
  openLinkInNewTab,
  handleExternalTargets,
  collectNodesInRange
};
