import type { FetchResult, MutationFunctionOptions } from '@apollo/client';
import type {
  CreateUploadedImageError,
  CreateUploadedImageInput,
  DisallowedImageFormat,
  Exact,
  Scalars,
  UploadedImageAssociatedEntityType,
  UploadTooLarge
} from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  UploadedImageFragment,
  UploadImageMutation
} from '@aurora/shared-generated/types/graphql-types';
import type { FormatMessage } from '@aurora/shared-types/texts';
import { getLog } from '@aurora/shared-utils/log';
import prettyBytes from 'pretty-bytes';
import type React from 'react';
import type { ClassNamesFnWrapper } from 'react-bootstrap/lib/esm/createClassNames';
import type { FileRejection } from 'react-dropzone-kh';
import type { Editor } from 'tinymce';
import { ToastAlertVariant, ToastVariant } from '../../components/common/ToastAlert/enums';
import type ToastProps from '../../components/common/ToastAlert/ToastAlertProps';
import type { AllowedExtensionsAndMimeTypesByEntityType } from '../../components/images/useImageExtensionsByEntityType';
import type { ImageUploadProperties } from '../../components/images/useImageUploadProperties';
import { getExternalVideoConsentCookie } from '../cookie/CookieHelper';
import { getImagePreviewHref } from '../images/FileHelper';
import FileOption from '../images/FileOption';
import getErrorsFromFileRejections from '../images/ValidationHelper';
// eslint-disable-next-line import/no-cycle
import {
  AlignmentOptions,
  applyFormat,
  ContentAlign,
  CustomEditorButton,
  getActiveEditor,
  insertContentWithParagraph,
  isCaptionSelected,
  removeInternalDataAttributes,
  setCursorAtEndOfNode
} from './EditorHelper';
import type { LinkInfo } from './EditorLinkHelperInternal';
import { extractFromAnchor, getAnchorElement, unlinkDomMutation } from './EditorLinkHelperInternal';
import { isVideoFigure } from './EditorVideoHelper';
import { getPlaceholderAttributeValue } from './EditorWordPasteHelper';
import type {
  BaseToolbarToggleButtonInstanceApi,
  Format,
  MenuItemSpec,
  OnAction
} from './TinyMceInternalHelper';
import { Actions, DATA_MCE_AUTOCOMPLETER, DATA_MCE_BOGUS } from './TinyMceInternalHelper';

const log = getLog(module);

/*
  The `image` class name is added to the `figure` element which is used internally by TinyMCE to handle
  the resize interaction via resize handles.
 */
const IMAGE = 'image';
const LI_IMAGE_ELEMENT = 'li-image';
export const DATA_IMAGE_CONTAINER = 'data-lia-image-container';
export const DATA_VIDEO_CONTAINER = 'data-lia-video-container';
export const DATA_MEDIA = 'data-lia-image';
export const DATA_MEDIA_CAPTION = 'data-lia-image-caption';
const DATA_IMAGE_LOADING = 'data-lia-image-loading';
export const DATA_MEDIA_SIZE = 'data-lia-image-size';
export const DATA_MEDIA_RESIZED = 'data-lia-image-resized';
export const DATA_MEDIA_ALIGN = 'data-lia-image-align';
export const DATA_MEDIA_LAYOUT = 'data-lia-image-layout';
export const DATA_MEDIA_PROGRESS = 'data-lia-progress-bar';
const DATA_IMAGE_NAME = 'data-lia-image-name';
export const MEDIA_WRAPPER_CLASS = 'lia-media-object';

const GHOST_ELEMENT_CLASS = 'mce-clonedresizable';
const GHOST_HELPER_CLASS = 'mce-resize-helper';

/**
 * Media Sizes.
 */
export enum MediaSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export const MEDIA_SIZE_DEFAULT = 'default';

/**
 * Image layouts
 */
export enum ImageLayout {
  INLINE = 'inline',
  STRETCH = 'stretch'
}

/**
 * Properties of an image. These are typically the attributes of the `li-image` tag.
 */
interface ImageData {
  /**
   * ID of the image.
   */
  id: string;
  /**
   * The URL for the image.
   */
  src: string;
  /**
   * The srcset for the image.
   */
  srcset?: string;
  /**
   * The alternative text for the image.
   */
  alt?: string;
  /**
   * The caption for the image.
   */
  caption?: string;
  /**
   * The image size.
   */
  size?: string;
  /**
   * Whether the image has been resized (true for the free-form resize case).
   */
  resized?: string;
  /**
   * The width of the image.
   */
  width?: string;
  /**
   * The height of the image
   */
  height?: string;
  /**
   * The alignment of the image
   */
  align?: string;
  /**
   * The layout of the image
   */
  layout?: string;
  /**
   * The name of the image
   */
  name?: string;
}

/**
 * Properties of a link. These are typically the attributes of an anchor tag.
 */
interface AnchorData {
  /**
   * The URL that the hyperlink points to.
   */
  href: string;
  /**
   * The title for the hyperlink.
   */
  title?: string;
  /**
   * Where to display the linked URL, as the name for a browsing context (a tab, window, or <iframe>).
   */
  target?: string;
}

/**
 * Map to store the image markups along with ids.
 */
export interface ImageUploadMarkUpMap {
  /**
   * The Html markup of the image uploaded.
   */
  html: string;
  /**
   * The id of the image uploaded.
   */
  id: string;
}

export interface ImageUploadPromise {
  /**
   * The id of the image.
   */
  id: string;
  /**
   * The result from the uploadImage mutation.
   */
  upload: Promise<FetchResult<UploadImageMutation>>;
}

/**
 * Regular expression for URL validation.
 */
export const urlRegEx = new RegExp(
  '(http|https)://[A-Za-z0-9]+(.[A-Za-z0-9]+)+([A-Za-z0-9.,@?^=%&:/~+#-]*[a-zA-Z0-9@?^=%&;/~+#-])?'
);

/**
 * Whether the specified element is a `figure`.
 *
 * @param node the element to check.
 */
const isImageFigure = (node: Element) =>
  node &&
  node.nodeName === 'FIGURE' &&
  /image/i.test(node.className) &&
  node.hasAttribute(DATA_IMAGE_CONTAINER);

/**
 * Whether the specified element is a `span` which is the image wrapper.
 *
 * @param node the element to check.
 */
const isImageWrapperSpan = (node: Element) =>
  node &&
  node.nodeName === 'SPAN' &&
  /image/i.test(node.className) &&
  node.hasAttribute(DATA_IMAGE_CONTAINER);

/**
 * Whether the specified element is the image wrapper.
 *
 * @param node the element to check.
 */
const isMediaWrapperElement = (node: HTMLElement) =>
  node && node.nodeName === 'BUTTON' && node.closest('.lia-media-object');

/**
 * Whether the specified element is a `figcaption`.
 *
 * @param node the element to check.
 */
const isFigCaption = (node: Element) =>
  node && node?.nodeName === 'FIGCAPTION' && node?.hasAttribute(DATA_MEDIA_CAPTION);

/**
 * Whether the current selection is a `figure` with `data-lia-image-container` data attribute.
 *
 * @param editor the TinyMCE Editor.
 */
function isMediaSelection(editor: Editor) {
  const node = editor.selection.getStart();
  return editor.dom.is(node, `[${DATA_IMAGE_CONTAINER}]`);
}

/**
 * Get the media size for the currently selected figure element.
 *
 * @param editor the TinyMCE Editor.
 */
const getMediaSize = (editor: Editor): string => {
  const selection = editor.selection.getNode();
  return isImageFigure(selection) ? selection.getAttribute(DATA_MEDIA_SIZE) : MediaSize.LARGE;
};

/**
 * Get the media alignment for the currently selected figure element.
 *
 * @param editor the TinyMCE Editor.
 * @returns value of data-lia-image-align
 */
const getMediaAlignment = (editor: Editor): string => {
  const selection = editor.selection.getNode();
  return selection.getAttribute(DATA_MEDIA_ALIGN) ?? ContentAlign.CENTER;
};

/**
 * Toggles the active state of a button in the RTE toolbar.
 *
 * @param editor the TinyMCE Editor.
 */
const toggleActiveState = (editor: Editor) => api => {
  return Actions.toggleState(editor, () => {
    api.setActive(isMediaSelection(editor));
  });
};

/**
 * Get the image element from the container (wrapper figure element).
 *
 * @param container
 */
function getImgElement(container: Element): HTMLImageElement | HTMLIFrameElement {
  if (isImageFigure(container) || isVideoFigure(container) || isImageWrapperSpan(container)) {
    return container.querySelector(`[${DATA_MEDIA}]`);
  }
  if (isFigCaption(container)) {
    return container.parentNode.querySelector(`[${DATA_MEDIA}]`);
  }
  return null;
}

/**
 * Get anchor element within a `figure`.
 *
 * @param figureElement the figure element.
 */
function getAnchorWithinFigure(figureElement: Element): HTMLAnchorElement {
  const anchorElement = figureElement.querySelector<HTMLAnchorElement>(
    `[${DATA_IMAGE_CONTAINER}] > a[href]`
  );
  return anchorElement?.firstElementChild?.hasAttribute(DATA_MEDIA) ? anchorElement : null;
}

/**
 * Get the href from the selected image element
 *
 * @param editor the tinymce editor
 */
export function getImageLinkInfoFromEditor(editor: Editor | undefined): LinkInfo {
  if (editor?.selection) {
    const anchorNode = getAnchorElement(editor);
    return extractFromAnchor(editor, anchorNode);
  }
  return { href: '' };
}

/**
 * Get the image CSS class for a given property and value.
 *
 * @param property a property - is/size/layout.
 * @param value the value of the property.
 */
function getMediaClass(property: string, value: string): string {
  return `lia-media-${property}-${value}`;
}

/**
 * Sets the layout CSS class and it's corresponding data attribute to the `figure` element based on the image's width.
 *
 * @param figureElement `figure` element
 * @param isNaturalSize Whether the image is currently in it's natural size
 * @param width Width of the image
 * @param editorWidth Width of the editor's body
 */
function setLayout(
  figureElement: Element,
  width: number,
  editorWidth: number,
  isNaturalSize = true,
  align = ContentAlign.CENTER
): void {
  const largeSizeClass = getMediaClass('size', MediaSize.LARGE);
  const defaultSizeClass = getMediaClass('size', MEDIA_SIZE_DEFAULT);

  const centerAlignClass = getMediaClass('is', ContentAlign.CENTER);
  const leftAlignClass = getMediaClass('is', ContentAlign.LEFT);
  const rightAlignClass = getMediaClass('is', ContentAlign.RIGHT);

  if (isNaturalSize && width >= editorWidth) {
    figureElement.setAttribute(DATA_MEDIA_LAYOUT, ImageLayout.STRETCH);

    switch (align) {
      case ContentAlign.RIGHT: {
        figureElement.classList.add(largeSizeClass, rightAlignClass);
        figureElement.classList.remove(leftAlignClass, centerAlignClass);
        figureElement.setAttribute(DATA_MEDIA_ALIGN, ContentAlign.RIGHT);
        break;
      }
      case ContentAlign.LEFT: {
        figureElement.classList.add(largeSizeClass, leftAlignClass);
        figureElement.classList.remove(centerAlignClass, rightAlignClass);
        figureElement.setAttribute(DATA_MEDIA_ALIGN, ContentAlign.LEFT);
        break;
      }
      default: {
        figureElement.classList.add(largeSizeClass, centerAlignClass);
        figureElement.classList.remove(defaultSizeClass, leftAlignClass, rightAlignClass);
        figureElement.setAttribute(DATA_MEDIA_ALIGN, ContentAlign.CENTER);
        figureElement.setAttribute(DATA_MEDIA_SIZE, MediaSize.LARGE);
        break;
      }
    }
  } else {
    figureElement.classList.remove(largeSizeClass);
    figureElement.setAttribute(DATA_MEDIA_LAYOUT, ImageLayout.INLINE);
  }
}

/**
 *  Since the images are floated element, checking if we have empty elements
 *  as siblings and setting clear property to the next sibling to control the
 *  flow next to floated elements
 *
 *  @param figureElement `figure` element
 */
function handleFloatedElements(figureElement: Element) {
  const nextNode = figureElement.nextElementSibling as HTMLElement;
  if (!nextNode) {
    return;
  }

  if (
    nextNode.nodeName === 'P' &&
    (nextNode.innerHTML === '&nbsp;' ||
      nextNode.innerHTML === '' ||
      nextNode.innerHTML.includes('br'))
  ) {
    nextNode.classList.add('lia-clear-both');
  }
}

/**
 * Get the width of parent container which maybe a spoiler or the editor itself
 *
 * @param element any element
 * @param editor the tinymce editor
 */
function getContainerElementWidth(element: HTMLElement, editor: Editor) {
  const spoilerContainer = element.closest('[data-lia-spoiler-content-wrapper]') as HTMLElement;
  return spoilerContainer ? spoilerContainer.offsetWidth : editor.getBody().offsetWidth;
}

/**
 * Get the image's alignment format configurations
 *
 * @param align Required alignment
 */
function getImageAlignFormat(align: string): Format {
  return {
    selector: 'figure.image, figure.iframe',
    ceFalseOverride: true,
    attributes: { [DATA_MEDIA_ALIGN]: align },
    onformat: (element: HTMLElement) => {
      element.classList.remove(
        'lia-media-is-no-align',
        'lia-media-is-left',
        'lia-media-is-right',
        'lia-media-is-center'
      );
      element.classList.add(getMediaClass('is', align));
    }
  };
}

/**
 * Locates the backing Element of an image from a corresponding TinyMce `Node`.
 *
 * @param editor the TinyMce editor
 * @param id the image id
 */
function getImageWrapperById(editor: Editor, id: string): HTMLElement | null {
  if (editor) {
    return editor.getBody()?.querySelector(`[${DATA_IMAGE_CONTAINER}="${id}"]`);
  }
  return null;
}

/**
 * Inserts the images into the RTE. If there are multiple, the last image (figure) is selected.
 *
 * @param editor the TinyMCE Editor.
 * @param markup the image(s) markup to be inserted into the RTE.
 * @param lastImageId the value of the `data-lia-image-container` attribute of the last image to be inserted.
 */
function insertImageMarkup(editor: Editor, markup: string, lastImageId: string): void {
  insertContentWithParagraph(editor, markup);
  const lastImageContainer = getImageWrapperById(editor, lastImageId);
  const lastImage = lastImageContainer?.querySelector(`[${DATA_MEDIA}]`);
  if (lastImage) {
    lastImage.addEventListener(
      'load',
      function scrollToImage() {
        editor.undoManager.transact(() => {
          editor.selection.scrollIntoView(lastImageContainer, true);
          lastImage.removeEventListener('load', scrollToImage, true);
        });
      },
      true
    );
  }
}

/**
 * Replaces the placeholder div with the images into the RTE.
 *
 * @param editor the TinyMCE Editor.
 * @param markup the image(s) markup to be inserted into the RTE.
 * @param wrapperId the value of the `data-lia-image-container` attribute of the last image to be inserted.
 */
function insertImageMarkupForCopyPaste(editor: Editor, markup: string, wrapperId: string): void {
  const lastImageContainer = getImageWrapperById(editor, wrapperId);

  if (lastImageContainer != null) {
    const div = document.createElement('div');
    div.innerHTML = markup.trim();
    lastImageContainer.parentNode.replaceChild(div.firstChild, lastImageContainer);
  }
  const imageContainerId = lastImageContainer?.dataset?.liaImageContainer;
  const imageContainer = editor
    .getBody()
    ?.querySelector(`[${DATA_IMAGE_CONTAINER}="${imageContainerId}"]`);
  const nextSibling = imageContainer.nextElementSibling as HTMLElement;
  if (nextSibling?.textContent.trim().length === 0) {
    imageContainer.scrollIntoView(true);
  }
}

/**
 * Inserts the image markup from a list of image promises result.
 *
 * @param imagePromises the image markup promises result.
 * @param editor the Editor.
 * @param lastImageId the id of the last image inserted into the RTE.
 * @param onMediaUploadComplete
 */
function insertImagesFromPromises(
  imagePromises: PromiseSettledResult<ImageUploadMarkUpMap>[],
  editor: Editor,
  lastImageId: string,
  onMediaUploadComplete: () => void
): PromiseSettledResult<ImageUploadMarkUpMap>[] {
  const markup = imagePromises
    .filter(value => value.status === 'fulfilled')
    .map(value => (value as PromiseFulfilledResult<ImageUploadMarkUpMap> | undefined)?.value?.html)
    .join('');
  insertImageMarkup(editor, markup, lastImageId);
  if (onMediaUploadComplete) {
    onMediaUploadComplete();
  }
  return imagePromises;
}

/**
 * Inserts the image markup from a list of image promises results that are part of clip board content.
 *
 * @param imagePromises the image markup promises result.
 * @param editor the Editor.
 * @param onMediaUploadComplete
 */
function insertImagesFromPromisesForCopyPaste(
  imagePromises: PromiseSettledResult<ImageUploadMarkUpMap>[],
  editor: Editor,
  onMediaUploadComplete: () => void
): PromiseSettledResult<ImageUploadMarkUpMap>[] {
  imagePromises.forEach(value => {
    insertImageMarkupForCopyPaste(
      editor,
      (value as PromiseFulfilledResult<ImageUploadMarkUpMap> | undefined).value?.html,
      (value as PromiseFulfilledResult<ImageUploadMarkUpMap> | undefined).value?.id
    );
  });

  if (onMediaUploadComplete) {
    onMediaUploadComplete();
  }
  return imagePromises;
}

/**
 * Handles the DOM manipulation required once an image is uploaded to the server.
 *
 * @param editor the TinyMCE Editor.
 * @param tempId the temporary id that was added to the element.
 * @param finalId the image id from the server.
 * @param imageDeletedMap
 */
function handleUploadSuccess(
  editor: Editor,
  tempId: string,
  finalId: string,
  imageDeletedMap: Map<string, boolean>
): void {
  const isImageDeleted = imageDeletedMap.get(finalId);
  if (!isImageDeleted) {
    const wrapperElement = getImageWrapperById(editor, tempId);
    if (wrapperElement) {
      const loaderWrapperElement = wrapperElement.querySelector<HTMLElement>('[data-lia-loader]');

      // fade loaderWrapperElement by 300ms which is defined via transition CSS for this element
      loaderWrapperElement.style.opacity = '0';
      setTimeout(() => {
        loaderWrapperElement.remove();
      }, 300);
      wrapperElement.setAttribute(DATA_IMAGE_CONTAINER, finalId);
      wrapperElement.setAttribute(DATA_IMAGE_LOADING, 'false');
    }
    editor.save();
  }
}

/**
 * Adds `load` event listener to the `img` element which handles the removal of loading animation on load of the image.
 *
 * @param editor the TinyMCE Editor.
 * @param tempId the temporary id added to `data-lia-image-container` attribute of `figure` element.
 * @param finalId the image id from the server.
 * @param url the image URL from the server.
 * @param onImageUploadComplete
 */
function onImageLoad(
  editor: Editor,
  tempId: string,
  finalId: string,
  url: string,
  getImageDeletedMap: () => Map<string, boolean>,
  onImageUploadComplete: () => void
): void {
  const figure = editor.getBody().querySelector(`[${DATA_IMAGE_CONTAINER}="${tempId}"]`);
  const imgElement = getImgElement(figure) as HTMLImageElement;
  editor.undoManager.transact(() => {
    onImageUploadComplete();
    if (imgElement.getAttribute('src') !== url) {
      imgElement.setAttribute('src', url);
    }
    handleUploadSuccess(editor, tempId, finalId, getImageDeletedMap());
    setCursorAtEndOfNode(editor, editor.selection.getNode());
  });
}

/**
 * Returns the loading indicator while the media is getting uploaded.
 *
 * @param editorCx editor styles.
 * @param loadingCx loading styles.
 */
function getLoadingIndicator(editorCx: ClassNamesFnWrapper, loadingCx: ClassNamesFnWrapper) {
  const imageLoadingWrapperClass = editorCx('lia-image-loading-wrapper');
  const loadingClass = editorCx('lia-is-loading');
  const loadingBarWrapClass = loadingCx('lia-loading-bar-wrap');
  const loadingBarClass = loadingCx('lia-loading-bar lia-loading-bar-light');
  return `
    <div contenteditable="false" class="${imageLoadingWrapperClass}" data-lia-loader>
        <div class="${loadingClass}">
            <div class="${loadingBarWrapClass}">
                <div class="${loadingBarClass}">
                    <div role="progressbar" ${DATA_MEDIA_PROGRESS}></div>
                </div>
            </div>
        </div>
    </div>`;
}

/**
 * Create the image HTML to be inserted into the RTE. Looks like:
 *
 * ```
 * <figure contenteditable="false">
 *   <img/>
 *   <figcaption contenteditable="true"> </figcaption>
 * </figure>
 * ```
 *
 * @param imageData the ImageData
 * @param formatMessage localizes messages.
 * @param loadingCx classname mapper for Loading state component.
 * @param editorCx classname mapper for the RTE.
 * @param isLoading whether the image is loading.
 * @param hasAnchor whether the image is wrapped in an anchor tag.
 * @param anchorData the attributes of the anchor tag.
 */
function createImageTemplate(
  imageData: ImageData,
  formatMessage: FormatMessage,
  loadingCx: ClassNamesFnWrapper,
  editorCx: ClassNamesFnWrapper,
  isLoading: boolean,
  hasAnchor?: boolean,
  anchorData?: AnchorData,
  showCaptionOnImage?: boolean
): ImageUploadMarkUpMap {
  const figureClass = editorCx('lia-figure');
  const figcaptionClass = editorCx('lia-image-caption');
  const placeholderAttr = `data-lia-placeholder="${formatMessage('mediaCaptionPlaceholder')}"`;
  const {
    id,
    src,
    srcset = '',
    alt = '',
    caption = '',
    size = MEDIA_SIZE_DEFAULT,
    resized = 'false',
    width = '',
    height = '',
    align = ContentAlign.CENTER,
    layout = ImageLayout.STRETCH,
    name = ''
  } = imageData;
  const { href, title, target } = anchorData ?? {};

  const imgHtml = srcset
    ? `<img src="${src}" srcset="${srcset}" data-lia-image alt="${alt}" width="${width}" height="${height}"/>`
    : `<img src="${src}" data-lia-image alt="${alt}" width="${width}" height="${height}"/>`;
  const anchorWrappedImgHtml = `<a href="${href}" title="${title}" target="${target}">${imgHtml}</a>`;

  const mediaSizeClass = getMediaClass('size', size);
  const mediaAlignClass = getMediaClass('is', align);

  const captionHtml =
    showCaptionOnImage ?? true
      ? `<figcaption contenteditable="true" class="${figcaptionClass}" ${placeholderAttr} ${DATA_MEDIA_CAPTION}>${caption}</figcaption>
    </figure>`
      : '';
  return {
    html: `<figure contenteditable="false" class="${figureClass} ${IMAGE} ${mediaSizeClass} ${mediaAlignClass}"
    ${DATA_IMAGE_CONTAINER}="${id}" ${DATA_IMAGE_LOADING}="${isLoading}"
    ${DATA_MEDIA_SIZE}=${size} ${DATA_MEDIA_RESIZED}=${resized} ${DATA_MEDIA_ALIGN}="${align}"
    ${DATA_MEDIA_LAYOUT}="${layout}" ${DATA_IMAGE_NAME}="${name}">
  ${isLoading ? getLoadingIndicator(editorCx, loadingCx) : ''}
  ${hasAnchor ? anchorWrappedImgHtml : imgHtml}
  ${captionHtml}`,
    id
  };
}

/**
 * Creates the image HTML markup for a given file input.
 *
 * @param file
 * @param id
 * @param formatMessage
 * @param loadingCx
 * @param editorCx
 */
async function createImageHtmlFromFile(
  file: File,
  id: string,
  formatMessage: FormatMessage,
  loadingCx: ClassNamesFnWrapper,
  editorCx: ClassNamesFnWrapper,
  showCaptionOnImage?: boolean
): Promise<ImageUploadMarkUpMap> {
  try {
    const previewHref = await getImagePreviewHref(file);
    return createImageTemplate(
      { id, src: previewHref },
      formatMessage,
      loadingCx,
      editorCx,
      true,
      undefined,
      undefined,
      showCaptionOnImage ?? true
    );
  } catch {
    // TODO: handle adding placeholder image if loading preview from local file fails
  }
  return null;
}

/**
 * Removes the figure element with the specified `id`.
 *
 * @param editor the TinyMCE Editor.
 * @param id the temporary ID set on the figure element.
 */
function removeFigure(editor: Editor, id: string): void {
  getImageWrapperById(editor, id)?.remove();
  editor.selection.setCursorLocation(editor.selection.getNode(), 0);
}

/**
 * Registers a node filter which converts `li-image` XML to HTML composed of `figure`, `img` and `figcaption`.
 *
 * @param editor the Editor
 * @param loadingCx the classname mapper for Loading state component
 * @param editorCx the classname mapper for the RTE
 * @param formatMessage localizes messages
 */
function registerXmlToHtmlConverter(
  editor: Editor,
  loadingCx: ClassNamesFnWrapper,
  editorCx: ClassNamesFnWrapper,
  formatMessage: FormatMessage
): void {
  editor.parser.addNodeFilter(LI_IMAGE_ELEMENT, () => {
    setTimeout(() => {
      editor
        .getBody()
        .querySelectorAll(LI_IMAGE_ELEMENT)
        .forEach(element => {
          const resized = element.getAttribute('resized');
          const imageData: ImageData = {
            id: element.getAttribute('id'),
            src: element.getAttribute('src'),
            srcset: element.getAttribute('srcset'),
            alt: element.getAttribute('alt'),
            caption: element.innerHTML,
            align: element.getAttribute('align'),
            layout: element.getAttribute('layout'),
            name: element.getAttribute('name'),
            resized
          };
          if (resized === 'true') {
            imageData.width = element.getAttribute('width');
            imageData.height = element.getAttribute('height');
          } else {
            imageData.size = element.getAttribute('size');
          }
          const isAnchorParent = element.parentElement.matches('a[href]');
          if (isAnchorParent) {
            const anchorElement = element.parentElement;
            const anchorData: AnchorData = {
              href: anchorElement.getAttribute('href'),
              title: anchorElement.getAttribute('title') ?? '',
              target: anchorElement.getAttribute('target') ?? '_blank'
            };
            anchorElement.outerHTML = createImageTemplate(
              imageData,
              formatMessage,
              loadingCx,
              editorCx,
              false,
              true,
              anchorData
            ).html;
          } else {
            const hasAnchor = element.getAttribute('link') === 'true';
            const anchorData: AnchorData = hasAnchor
              ? {
                  href: element.getAttribute('linkurl'),
                  title: element.getAttribute('linktitle') ?? '',
                  target: element.getAttribute('linktarget') ?? '_blank'
                }
              : null;

            element.outerHTML = createImageTemplate(
              imageData,
              formatMessage,
              loadingCx,
              editorCx,
              false,
              hasAnchor,
              anchorData
            ).html;
          }
        });
    });
  });
}

/**
 * Registers an attribute filter that converts the HTML used for displaying the
 * image when in TinyMce editor to the backing XML used for storing the image contents.
 * The registered attribute filter is only triggered when the contents of the editor are
 * serialized for sending the final value to the server (or for use by the container form).
 *
 * @param editor the TinyMceEditor
 */
function registerHtmlToXmlConverter(editor: Editor): void {
  // Watch for HTML elements that have the `data-lia-image-container` data attribute
  editor.serializer.addAttributeFilter(DATA_IMAGE_CONTAINER, nodes => {
    nodes.forEach(node => {
      let showCaption = 'false';
      let captionContents = '';
      let captionHtmlContents;
      let anchorElement: HTMLAnchorElement = null;
      const imageId = node.attr(DATA_IMAGE_CONTAINER);
      const containerElement = getImageWrapperById(editor, imageId);
      const imgElement = getImgElement(containerElement);
      const nextSiblingElement = imgElement?.nextElementSibling;
      if (containerElement) {
        anchorElement = getAnchorWithinFigure(containerElement);

        const captionElement = containerElement.querySelector<HTMLDivElement>(
          `[${DATA_MEDIA_CAPTION}]`
        );
        if (captionElement) {
          // Remove TinyMce br tags
          captionElement.querySelector(`${DATA_MCE_BOGUS}`)?.remove();
          // Remove TinyMce data- attributes on links
          removeInternalDataAttributes(captionElement.querySelectorAll('a'));
          captionHtmlContents = captionElement.innerHTML.replaceAll('&nbsp;', '').trim();
          if (captionHtmlContents?.length > 0) {
            showCaption = 'true';
            captionContents = captionHtmlContents;
          }
        } else if (nextSiblingElement && nextSiblingElement.tagName.toLowerCase() === 'p') {
          const brElement = nextSiblingElement.querySelector(`[${DATA_MCE_BOGUS}]`);
          if (brElement) {
            brElement.remove();
            nextSiblingElement.remove();
          }
        }
      }

      const domParser = window.tinymce.html.DomParser(
        editor.editorManager.defaultOptions,
        editor.schema
      );
      const liImageTemplate = `<li-image>${captionContents}</li-image>`;
      const liImageNode = domParser.parse(liImageTemplate).firstChild;
      liImageNode.attr('caption', showCaption);
      liImageNode.attr('id', imageId);
      liImageNode.attr('layout', containerElement.getAttribute(DATA_MEDIA_LAYOUT));
      liImageNode.attr('src', imgElement?.getAttribute('src'));
      liImageNode.attr('alt', imgElement?.getAttribute('alt'));
      const resized = containerElement.getAttribute(DATA_MEDIA_RESIZED);
      liImageNode.attr('resized', resized);
      if (resized === 'true') {
        liImageNode.attr('width', String(imgElement.width));
        liImageNode.attr('height', String(imgElement.height));
      } else {
        liImageNode.attr('size', containerElement.getAttribute(DATA_MEDIA_SIZE));
      }
      liImageNode.attr('align', containerElement.getAttribute(DATA_MEDIA_ALIGN));
      liImageNode.attr('name', containerElement.getAttribute(DATA_IMAGE_NAME));

      if (anchorElement) {
        liImageNode.attr('link', 'true');
        liImageNode.attr('linkurl', anchorElement.getAttribute('href'));
        const linkTitle = anchorElement.getAttribute('title');
        if (linkTitle) {
          liImageNode.attr('linktitle', linkTitle);
        }
        const linkTarget = anchorElement.getAttribute('target') ?? '_blank';
        liImageNode.attr('linktarget', linkTarget);
      }

      node.replace(liImageNode);
    });
  });
}

/**
 * Remove the width and height for `img` and add size specific data attributes and classname for `figure`.
 *
 * @param figureElement the `figure` element.
 * @param mediaSize the media size.
 */
export function removeDimensionsSetSize(figureElement: Element, mediaSize: MediaSize): void {
  const imgElement = getImgElement(figureElement);
  if (imgElement && imgElement.nodeName === 'IMG') {
    imgElement.removeAttribute('width');
    imgElement.removeAttribute('height');
  }
  figureElement.setAttribute(DATA_MEDIA_SIZE, mediaSize);
  figureElement.setAttribute(DATA_MEDIA_RESIZED, 'false');
  figureElement.classList.add(getMediaClass('size', mediaSize));
}

/**
 * Adds the image's name to the 'data-lia-image-name' data attribute.
 * This data attribute helps in carrying the image name meta-data to the required HTML parsing logic.
 * Used in CustomContentWidget image upload.
 *
 * @param imageId id of the image(time of upload)
 * @param imageName name of the image
 */
function addImageNameData(imageId: string, imageName: string) {
  const editor = getActiveEditor();
  const figureElement = getImageWrapperById(editor, imageId);
  editor.undoManager.transact(() => {
    figureElement.setAttribute(DATA_IMAGE_NAME, imageName);
  });
}

/**
 * Removes the source and loading attributes from image.
 * @param messageBody
 */
function removeImageSourceAndLoadingAttributes(messageBody: string): string {
  const parser = new DOMParser();
  const parsedContent = parser.parseFromString(messageBody, 'text/html');
  const imageNodeList = parsedContent.querySelectorAll(LI_IMAGE_ELEMENT);

  imageNodeList.forEach(imgElement => {
    imgElement.removeAttribute('src');
    imgElement.removeAttribute('loading');
    if (imgElement.innerHTML.length > 0 && !imgElement.getAttribute('caption')) {
      imgElement.setAttribute('caption', 'true');
    }
  });
  return parsedContent.body.innerHTML;
}

/**
 * Handler to update the alt text for the selected image.
 * @param altText
 * @param selectedImageElementRef
 */
function handleAltTextUpdate(
  altText: string,
  selectedImageElementRef: React.MutableRefObject<Element>
): void {
  const editor = getActiveEditor();
  if (selectedImageElementRef.current) {
    editor.selection.select(selectedImageElementRef.current);
  }
  editor.undoManager.transact(() => {
    getImgElement(selectedImageElementRef.current)?.setAttribute('alt', altText);
  });
}

enum DeviceWidth {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
}

const mediaSizeForDeviceWidth: Partial<Record<MediaSize, Record<DeviceWidth, number>>> = {
  [MediaSize.SMALL]: {
    [DeviceWidth.SM]: 0,
    [DeviceWidth.MD]: 60,
    [DeviceWidth.LG]: 40,
    [DeviceWidth.XL]: 30
  },
  [MediaSize.MEDIUM]: {
    [DeviceWidth.SM]: 90,
    [DeviceWidth.MD]: 80,
    [DeviceWidth.LG]: 70,
    [DeviceWidth.XL]: 60
  }
};

/**
 * Gives the max allowed width of image to the editor for that particular device and resize size.
 * @param editorWidth the width of TinyMCE Editor
 * @param mediaSize button Media Size
 */
function getMaxWidthForImage(editorWidth: number, mediaSize: MediaSize): number {
  const { small: smallMediaSize, medium: mediumMediaSize } = mediaSizeForDeviceWidth;
  const smDevice = window.matchMedia('(min-width: 576px)').matches;
  const mdDevice = window.matchMedia('(min-width: 768px)').matches;
  const lgDevice = window.matchMedia('(min-width: 992px)').matches;
  const xlDevice = window.matchMedia('(min-width: 1260px)').matches;
  const isSmallImage = mediaSize === MediaSize.SMALL;
  if (mediaSize !== MediaSize.LARGE) {
    if (xlDevice) {
      return isSmallImage
        ? (smallMediaSize.xl / 100) * editorWidth
        : (mediumMediaSize.xl / 100) * editorWidth;
    }
    if (lgDevice) {
      return isSmallImage
        ? (smallMediaSize.lg / 100) * editorWidth
        : (mediumMediaSize.lg / 100) * editorWidth;
    }
    if (mdDevice) {
      return isSmallImage
        ? (smallMediaSize.md / 100) * editorWidth
        : (mediumMediaSize.md / 100) * editorWidth;
    }
    if (smDevice) {
      return isSmallImage
        ? (smallMediaSize.sm / 100) * editorWidth
        : (mediumMediaSize.sm / 100) * editorWidth;
    }
  }
  return editorWidth;
}

/**
 * Handles the enable/disable and active state of resize button on image content.
 * If the button is not going to change the size of the image, it will get disable.
 * @param editor The TinyMce Editor
 * @param figureElement The figure element where the resize buttons are registered.
 * @param mediaSize the media size.
 * @param api callback to detect and set Active and Disable state of buttons.
 */
function handleImageButtonStatesOnResize(
  editor: Editor,
  figureElement: Element,
  mediaSize: MediaSize,
  api: BaseToolbarToggleButtonInstanceApi
): void {
  const imageElement = getImgElement(figureElement) as HTMLImageElement;
  const imgOriginalWidth = imageElement?.naturalWidth;
  const editorWidth = getContainerElementWidth(imageElement, editor);
  const activeState = figureElement.getAttribute(DATA_MEDIA_SIZE);
  const resizeState = figureElement.getAttribute(DATA_MEDIA_RESIZED);
  const requestedMaxWidth = getMaxWidthForImage(editorWidth, mediaSize);

  if (imgOriginalWidth === 0) {
    api.setEnabled(true);
    api.setActive(false);
    return;
  }

  if (resizeState === 'false') {
    switch (mediaSize) {
      case MediaSize.LARGE: {
        api.setEnabled(imgOriginalWidth >= requestedMaxWidth);
        break;
      }
      case MediaSize.MEDIUM: {
        const isImageOfMediumSize =
          getMaxWidthForImage(editorWidth, MediaSize.SMALL) > imgOriginalWidth;
        api.setEnabled(!isImageOfMediumSize);
        break;
      }
      default: {
        break;
      }
    }
    api.setActive(activeState === mediaSize);
  } else {
    api.setEnabled(true);
  }
}

/**
 * Handles the active state of resize button on a video content.
 * @param editor The TinyMce Editor
 * @param figureElement The figure element where the resize buttons are registered.
 * @param mediaSize the media size.
 * @param api callback to detect and set Active state of buttons.
 */
function handleVideoButtonStatesOnResize(
  editor: Editor,
  figureElement: Element,
  mediaSize: MediaSize,
  api: BaseToolbarToggleButtonInstanceApi
): void {
  const activeState = figureElement.getAttribute(DATA_MEDIA_SIZE);
  api.setActive(activeState === mediaSize);
}

/**
 * Handles image resize. If the resize is free-form, the `width` and `height` are set for the `img` and
 * `data-lia-image-size` is removed from `figure`. If resized using the S, M, L controls, the `data-image-size`
 * is added to the `figure` and `width` and `height` are removed from the `img`. `data-lia-image-resized` attribute
 * is set irrespective of free-form or S, M, L resize.
 *
 * @param editor the TinyMCE Editor.
 * @param mediaSize the media size.
 * @param freeFormResize whether the resize action is free form, i.e. using drag resize handles.
 * @param targetElement the target element from ObjectResized event.
 * @param width the target width.
 * @param editorCx
 */
export function doResize(
  editor: Editor,
  mediaSize: MediaSize,
  freeFormResize: boolean,
  targetElement?: HTMLElement,
  width?: number,
  editorCx?: ClassNamesFnWrapper,
  isExternalVideoCookieBannerEnabled?: boolean
) {
  editor.undoManager.transact(() => {
    const selectedElement = targetElement ?? editor.selection.getNode();
    const canResize =
      isImageFigure(selectedElement) ||
      isFigCaption(selectedElement) ||
      isVideoFigure(selectedElement);
    if (canResize) {
      const figureElement =
        isImageFigure(selectedElement) || isVideoFigure(selectedElement)
          ? selectedElement
          : selectedElement.parentElement;
      const editorWidth = getContainerElementWidth(figureElement, editor);
      figureElement.classList.remove(
        getMediaClass('size', figureElement.getAttribute(DATA_MEDIA_SIZE))
      );
      const isExternalVideo = figureElement?.hasAttribute('data-lia-video-external');
      const showBannerOnVideo =
        isExternalVideo &&
        isExternalVideoCookieBannerEnabled &&
        !getExternalVideoConsentCookie() &&
        isVideoFigure(selectedElement);

      if (freeFormResize) {
        if (width >= editorWidth) {
          // set the img width as editor's width and height to auto
          const imgElement = getImgElement(figureElement);
          imgElement.setAttribute('width', String(editorWidth));
          imgElement.setAttribute('height', 'auto');
        }
        figureElement.removeAttribute(DATA_MEDIA_SIZE);
        figureElement.setAttribute(DATA_MEDIA_RESIZED, 'true');
      } else {
        let figureChildElement;
        if (!showBannerOnVideo) {
          figureChildElement = getImgElement(figureElement);
          if (figureChildElement.nodeName === 'IFRAME') {
            figureChildElement.width = getMaxWidthForImage(editorWidth, mediaSize);
            figureChildElement.height = 'auto'; // Aspect ratio of 16:9 is set from CSS
          }
        } else {
          figureChildElement = figureElement.querySelector(
            `.${editorCx('lia-cookie-banner-container')}`
          );
        }
        if (figureChildElement?.nodeName === 'DIV') {
          const expectedWidth = getMaxWidthForImage(editorWidth, mediaSize);
          const height = Math.round((9 / 16) * expectedWidth);
          figureChildElement.style.height = `${height}px`;
          figureChildElement.style.width = `${expectedWidth}px`;
          figureChildElement.dataset.liaVideoPlaceholderHeight = String(height);
          figureChildElement.dataset.liaVideoPlaceholderWidth = String(expectedWidth);
          if (showBannerOnVideo) {
            const bannerElement = figureChildElement.querySelector(
              `.${editorCx('lia-cookie-banner')}`
            );
            const buttonElement = figureChildElement.querySelector(
              `.${editorCx('lia-cookie-consent-button')}`
            );
            const smallBannerClass = editorCx('lia-cookie-banner-small');
            const smallButtonClass = editorCx('lia-g-mt-5');
            const bannerElementClasses = bannerElement.classList;
            const buttonElementClasses = buttonElement.classList;
            if (mediaSize === MediaSize.SMALL) {
              bannerElementClasses.add(smallBannerClass);
              buttonElementClasses.add(smallButtonClass);
            } else {
              bannerElementClasses.remove(smallBannerClass);
              buttonElementClasses.remove(smallButtonClass);
            }
          }
        }
        removeDimensionsSetSize(figureElement, mediaSize);
        const elementWidth =
          (figureChildElement as HTMLImageElement).naturalWidth ||
          Number(figureChildElement.getAttribute('width'));
        const isLargeMedia = mediaSize === MediaSize.LARGE;
        if (isVideoFigure(selectedElement)) {
          setLayout(
            figureElement,
            isLargeMedia ? editorWidth : elementWidth,
            editorWidth,
            isLargeMedia
          );
        }
        handleFloatedElements(figureElement);
      }
      editor.selection.select(figureElement);
      editor.selection.scrollIntoView(figureElement as HTMLElement, true);
    }
  });
}

/**
 * We add the placeholder for `figcaption` element using the `:empty` pseudo selector. However, on clearing the
 * contents of `figcaption`, TinyMCE adds a `br` element with `data-mce-bogus` attribute which prevents the placeholder
 * from showing again. This hack adds an attribute filter which listens for nodes with `data-lia-image-caption`
 * attribute and removes any such `br` element.
 *
 * @param editor the TinyMCE Editor.
 */
function setupCaptionPlaceholderHack(editor: Editor): void {
  editor.serializer.addAttributeFilter(DATA_MEDIA_CAPTION, () => {
    editor
      .getBody()
      .querySelectorAll(`[${DATA_MEDIA_CAPTION}]`)
      .forEach(figCaption => {
        setTimeout(() => {
          const brElement = figCaption.querySelector(
            `[${DATA_MCE_BOGUS}]:not([${DATA_MCE_AUTOCOMPLETER}])`
          );
          if (brElement) {
            brElement.remove();
          }
        });
      });
  });
}

/**
 * Registers the context toolbar items for image selection in the RTE.
 *
 * @param editor
 * @param formatMessage
 * @param onActionForA11y
 */
export function registerContextToolbarItems(
  editor: Editor,
  formatMessage: FormatMessage,
  onActionForA11y: OnAction,
  onActionForImageLink: OnAction,
  editorCx: ClassNamesFnWrapper,
  isExternalVideoCookieBannerEnabled?: boolean
): void {
  editor.ui.registry.addToggleButton('liaImageLink', {
    icon: 'edit_link',
    tooltip: formatMessage('linkTooltip'),
    onAction() {
      onActionForImageLink();
    },
    onSetup: api => {
      const toggleImageLinkCallback = () => {
        const { href } = getImageLinkInfoFromEditor(editor);
        if (href !== '') {
          api.setActive(true);
        }
      };
      toggleImageLinkCallback();
      editor.on('NodeChange', toggleImageLinkCallback);
      return () => {
        editor.off('NodeChange', toggleImageLinkCallback);
        Actions.toggleState(editor, () => {
          api.setActive(true);
        });
      };
    }
  });

  editor.ui.registry.addToggleButton('liaImageUnlink', {
    icon: 'unlink',
    tooltip: formatMessage('unLinkTooltip'),
    onAction: () => unlinkDomMutation(editor, editor.selection ? editor.selection.getNode() : null),
    onSetup: api => {
      const toggleImageUnlinkCallback = () => {
        const { href } = getImageLinkInfoFromEditor(editor);
        if (href === '') {
          api.setEnabled(false);
        }
      };
      toggleImageUnlinkCallback();
      editor.on('NodeChange', toggleImageUnlinkCallback);
      return () => {
        editor.off('NodeChange', toggleImageUnlinkCallback);
        Actions.toggleState(editor, () => {
          api.setEnabled(true);
        });
      };
    }
  });

  editor.ui.registry.addButton(CustomEditorButton.ACCESSIBILITY, {
    tooltip: formatMessage(CustomEditorButton.ACCESSIBILITY),
    icon: 'accessibility',
    onAction() {
      onActionForA11y();
    }
  });

  Object.values(MediaSize).forEach(mediaSize => {
    editor.ui.registry.addToggleButton(mediaSize, {
      tooltip: formatMessage(mediaSize),
      icon: mediaSize,
      onAction: () =>
        doResize(
          editor,
          mediaSize,
          false,
          null,
          null,
          editorCx,
          isExternalVideoCookieBannerEnabled
        ),
      onSetup: api => {
        const editorEventCallback = () => {
          const figureElement = editor.selection.getNode();
          if (isImageFigure(figureElement)) {
            handleImageButtonStatesOnResize(editor, figureElement, mediaSize, api);
          } else if (isVideoFigure(figureElement)) {
            handleVideoButtonStatesOnResize(editor, figureElement, mediaSize, api);
          }
        };
        editorEventCallback();
        editor.on('NodeChange', editorEventCallback);
        return () => {
          editor.off('NodeChange', editorEventCallback);
          Actions.toggleState(editor, () => {
            api.setActive(mediaSize === getMediaSize(editor));
          });
        };
      }
    });
  });

  editor.ui.registry.addToggleButton(AlignmentOptions.ALIGN_NONE, {
    icon: 'align_none',
    tooltip: formatMessage('noAlignTooltip'),
    onAction() {
      applyFormat(editor, 'mediaalignnone');
    },
    onSetup: api => {
      const editorEventCallback = () => {
        const activeState = getMediaAlignment(editor);
        api.setActive(activeState === ContentAlign.NONE);
      };
      editorEventCallback();
      editor.on('NodeChange', editorEventCallback);
      return () => {
        editor.off('NodeChange', editorEventCallback);
        Actions.toggleState(editor, () => {
          editorEventCallback();
        });
      };
    }
  });

  editor.ui.registry.addToggleButton(AlignmentOptions.ALIGN_LEFT, {
    icon: 'align_left',
    tooltip: formatMessage('leftAlignTooltip'),
    onAction() {
      applyFormat(editor, 'mediaalignleft');
    },
    onSetup: api => {
      const editorEventCallback = () => {
        const activeState = getMediaAlignment(editor);
        api.setActive(activeState === ContentAlign.LEFT);
      };
      editorEventCallback();
      editor.on('NodeChange', editorEventCallback);
      return () => {
        editor.off('NodeChange', editorEventCallback);
        Actions.toggleState(editor, () => {
          editorEventCallback();
        });
      };
    }
  });

  editor.ui.registry.addToggleButton(AlignmentOptions.ALIGN_CENTER, {
    icon: 'align_center',
    tooltip: formatMessage('centerAlignTooltip'),
    onAction() {
      applyFormat(editor, 'mediaaligncenter');
    },
    onSetup: api => {
      const editorEventCallback = () => {
        const activeState = getMediaAlignment(editor);
        api.setActive(activeState === ContentAlign.CENTER);
      };
      editorEventCallback();
      editor.on('NodeChange', editorEventCallback);
      return () => {
        editor.off('NodeChange', editorEventCallback);
        Actions.toggleState(editor, () => {
          editorEventCallback();
        });
      };
    }
  });

  editor.ui.registry.addToggleButton(AlignmentOptions.ALIGN_RIGHT, {
    icon: 'align_right',
    tooltip: formatMessage('rightAlignTooltip'),
    onAction() {
      applyFormat(editor, 'mediaalignright');
    },
    onSetup: api => {
      const editorEventCallback = () => {
        const activeState = getMediaAlignment(editor);
        api.setActive(activeState === ContentAlign.RIGHT);
      };
      editorEventCallback();
      editor.on('NodeChange', editorEventCallback);
      return () => {
        editor.off('NodeChange', editorEventCallback);
        Actions.toggleState(editor, () => {
          editorEventCallback();
        });
      };
    }
  });
}

/**
 * Registers the context toolbar for the image selection in RTE.
 *
 * @param editor the TinyMCE Editor.
 * @param items the context toolbar items
 */
function registerContextToolbar(editor: Editor, items: string): void {
  editor.ui.registry.addContextToolbar(CustomEditorButton.MEDIA, {
    predicate: element => {
      const selectedNode = editor.selection.getNode();
      const isElementFigCaption = isFigCaption(selectedNode);
      return isImageFigure(element) && !(isElementFigCaption || selectedNode.closest('figcaption'));
    },
    items,
    position: 'selection'
  });
}

/**
 * Registers the context toolbar for the caption text selection in RTE.
 *
 * @param editor the TinyMCE Editor
 */
function registerCaptionSelectionContextToolbar(editor: Editor): void {
  editor.ui.registry.addContextToolbar('imageCaptionSelection', {
    predicate: (element: Element) => isCaptionSelected(element, editor),
    items: 'bold italic | liaLink',
    position: 'selection'
  });
}

/**
 * Hack to fix LIA-85543.
 * Registers an empty context toolbar for the caption tags in RTE.
 * We don't need to show any toolbar for caption area.
 * This creates a empty toolbar not visible to the user, to prevent rendering the media context toolbar up the DOM tree.
 *
 * @param editor the TinyMCE Editor
 */
function registerCaptionContextToolbar(editor: Editor): void {
  editor.ui.registry.addContextToolbar('imageCaption', {
    items: ''
  });
}

/**
 * Set the style for the ghost element.
 *
 * @param editor the TinyMCE editor.
 * @param ghostFigureElement the ghost resizable figure element.
 * @param imgElement the img element which is being resized.
 */
const setGhostStyleForCenterAlign = (
  editor: Editor,
  ghostFigureElement: HTMLElement,
  imgElement: HTMLImageElement
): void => {
  const ghostImgElement = getImgElement(ghostFigureElement) as HTMLImageElement;

  let translatePx;
  const ghostImageWidth = ghostImgElement.width;
  const actualImageWidth = imgElement.width;
  if (ghostImageWidth >= actualImageWidth) {
    const translateValue = (ghostImageWidth - actualImageWidth) / 2;
    translatePx = `-${translateValue}px`;
  } else if (ghostImageWidth < actualImageWidth) {
    const translateValue = (actualImageWidth - ghostImageWidth) / 2;
    translatePx = `${translateValue}px`;
  }
  ghostFigureElement.style.transform = `translate(${translatePx})`;
};

/**
 * Set the HTML for resize indicator (the one which shows width x height).
 *
 * @param editor the TinyMCE Editor.
 * @param ghostImgElement the ghost resizable img element.
 * @param imgElement the img element which is being resized.
 */
const setResizeIndicator = (
  editor: Editor,
  ghostImgElement: HTMLImageElement,
  imgElement: HTMLImageElement
): void => {
  const editorWidth = getContainerElementWidth(imgElement, editor);
  const { width, height } = imgElement;

  const resizeIndicator = editor.getBody().querySelector<HTMLElement>(`.${GHOST_HELPER_CLASS}`);

  if (ghostImgElement.width >= editorWidth) {
    const maxHeight = Math.round(height * (editorWidth / width));
    resizeIndicator.innerHTML = `${editorWidth} &#215; ${maxHeight}`;
  }
};

/**
 * Register the `ObjectResized` and `ObjectResizeStart` events.
 *
 * @param editor the TinyMCE Editor.
 */
function registerResizeEvents(
  editor: Editor,
  editorCx?: ClassNamesFnWrapper,
  isExternalVideoCookieBannerEnabled?: boolean
): void {
  let resizer;
  let resizeIndicator;

  editor.on('ObjectResized', event => {
    clearInterval(resizer);
    clearInterval(resizeIndicator);
    const { target, width } = event;
    if (isImageFigure(target) || isVideoFigure(target)) {
      doResize(
        editor,
        MediaSize.LARGE,
        true,
        target,
        width,
        editorCx,
        isExternalVideoCookieBannerEnabled
      );
    }
  });

  // Ghost element style hacks based on align type
  editor.on('ObjectResizeStart', event => {
    if (isImageFigure(event.target) || isVideoFigure(event.target)) {
      const {
        left: editorLeft,
        right: editorRight,
        width: editorWidth
      } = editor.getBody().getBoundingClientRect();

      const ghostFigureElement = editor
        .getBody()
        .querySelector<HTMLElement>(`.${GHOST_ELEMENT_CLASS}`);
      ghostFigureElement.style.maxWidth = `${editorWidth}px`;

      const figureElement = event.target;
      const imgElement = getImgElement(figureElement) as HTMLImageElement;
      const { naturalWidth, naturalHeight } = imgElement;
      const {
        left: imgLeft,
        right: imgRight,
        width: imgWidth
      } = imgElement.getBoundingClientRect();
      const maxHeight = Math.round(naturalHeight * (editorWidth / naturalWidth));

      const ghostImgElement = getImgElement(ghostFigureElement);
      ghostImgElement.style.maxWidth = `${editorWidth}px`;
      ghostImgElement.style.maxHeight = `${maxHeight}px`;

      const align = ghostFigureElement.getAttribute(DATA_MEDIA_ALIGN);
      switch (align) {
        case ContentAlign.LEFT: {
          ghostFigureElement.style.left = `${imgRight - (editorLeft + imgWidth)}px`;
          ghostFigureElement.style.right = 'unset';
          break;
        }
        case ContentAlign.RIGHT: {
          ghostFigureElement.style.left = 'unset';
          ghostFigureElement.style.right = `${editorRight - (imgLeft + imgWidth)}px`;
          break;
        }
        case ContentAlign.CENTER: {
          resizer = setInterval(
            setGhostStyleForCenterAlign,
            20,
            editor,
            ghostFigureElement,
            imgElement
          );
          break;
        }
        default: {
          break;
        }
      }
      resizeIndicator = setInterval(setResizeIndicator, 5, editor, ghostImgElement, imgElement);
    }
  });
}

/**
 * Register media button for mobile.
 *
 * @param editor the TinyMCE editor
 * @param formatMessage localizes messages
 * @param onActionForDevice a callback triggered when the media button in mobile is interacted with.
 * @param canUploadImage whether user can upload image
 * @param canUploadVideo whether user can upload video
 * @param canUploadExternalVideo whether user can upload external video
 */
function registerMediaButtonMobile(
  editor: Editor,
  formatMessage: FormatMessage,
  onActionForDevice: OnAction,
  canUploadImage: boolean,
  canUploadVideo: boolean,
  canUploadExternalVideo: boolean
): void {
  if (canUploadImage || canUploadVideo || canUploadExternalVideo) {
    editor.ui.registry.addButton(CustomEditorButton.MEDIA, {
      icon: 'image',
      tooltip: formatMessage('mediaTooltip'),
      onAction: onActionForDevice
    });
  }
}

/**
 * Register media button (menu button) for desktop.
 *
 * @param editor the TinyMCE Editor.
 * @param formatMessage localizes messages.
 * @param onActionForDevice a callback triggered when the dropdown action for computer is interacted with.
 * @param onActionForImageUrl a callback triggered when the dropdown option for image URL is interacted with.
 * @param onActionForVideoUrl a callback triggered when the dropdown option for video URL is interacted with.
 * @param canUploadImage
 * @param canUploadVideo whether video upload from computer is enabled.
 * @param canUploadExternalVideo whether external videos are supported in RTE.
 */
function registerMediaButtonDesktop(
  editor: Editor,
  formatMessage: FormatMessage,
  onActionForDevice: OnAction,
  onActionForImageUrl: OnAction,
  onActionForVideoUrl: OnAction,
  canUploadImage: boolean,
  canUploadVideo: boolean,
  canUploadExternalVideo: boolean
): void {
  if (canUploadVideo || canUploadImage || canUploadExternalVideo) {
    editor.ui.registry.addMenuButton(CustomEditorButton.MEDIA, {
      icon: 'image',
      tooltip: formatMessage('mediaTooltip'),
      onSetup: toggleActiveState(editor),
      fetch(callback) {
        const items: MenuItemSpec[] = [];
        if (canUploadImage || canUploadVideo) {
          items.push({
            type: 'menuitem',
            text: formatMessage('mediaFromComputer'),
            onAction: onActionForDevice
          });
        }
        if (canUploadImage) {
          items.push({
            type: 'menuitem',
            text: formatMessage('mediaFromImageUrl'),
            onAction: onActionForImageUrl
          });
        }
        if (canUploadExternalVideo) {
          items.push({
            type: 'menuitem',
            text: formatMessage('mediaFromVideoUrl'),
            onAction: onActionForVideoUrl
          });
        }
        callback(items);
      }
    });
  }
}

/**
 * Registers context toolbar and other utilities for media in RTE which are common for desktop and mobile view.
 *
 * @param editor the TinyMCE editor.
 * @param loadingCx the loading component ClassNameMapper.
 * @param editorCx the RTE component ClassNameMapper.
 * @param formatMessage localizes messages.
 * @param onActionForA11y a callback triggered when the a11y button is interacted with.
 * @param isImageSerialisationRequired whether image serialization required or not.
 */
function registerMediaCommon(
  editor: Editor,
  loadingCx: ClassNamesFnWrapper,
  editorCx: ClassNamesFnWrapper,
  formatMessage: FormatMessage,
  onActionForA11y: OnAction,
  onActionForImageLink: OnAction,
  isImageSerialisationRequired: boolean,
  isExternalVideoCookieBannerEnabled?: boolean
): void {
  registerContextToolbarItems(
    editor,
    formatMessage,
    onActionForA11y,
    onActionForImageLink,
    editorCx,
    isExternalVideoCookieBannerEnabled
  );
  registerCaptionContextToolbar(editor);
  registerCaptionSelectionContextToolbar(editor);

  editor.on('click', event => {
    const element = event.target as HTMLElement;

    if (element?.matches(`[${DATA_MEDIA}]`)) {
      const container = element.closest('figure');
      editor.selection.select(container);
    }

    if (isFigCaption(element)) {
      // Remove the existing context toolbar on the selected media element
      const toxElements = [...document.querySelectorAll('.tox-pop')];
      toxElements.map(toxElement => toxElement.remove());
    }
  });

  editor.on('preInit', () => {
    if (isImageSerialisationRequired) {
      registerXmlToHtmlConverter(editor, loadingCx, editorCx, formatMessage);
      registerHtmlToXmlConverter(editor);
    }
    setupCaptionPlaceholderHack(editor);
  });
}

/**
 * Register media button, context toolbar, converters and other utilities.
 *
 * @param editor the TinyMCE Editor.
 * @param loadingCx the Loading component classname mapper.
 * @param editorCx the RTE component classname mapper.
 * @param formatMessage localizes messages.
 * @param onActionForDevice a callback which triggers the input type file native click event.
 * @param onActionForImageUrl a callback when the dropdown option for image URL is interacted with.
 * @param onActionForVideoUrl a callback triggered when the dropdown option for video URL is interacted with.
 * @param onActionForImageLink
 * @param onActionForA11y a callback when the a11y button is interacted with.
 * @param isMobile whether the current device is a mobile.
 * @param isImageSerialisationRequired whether image serialization required or not.
 * @param canUploadImage
 * @param canUploadVideo whether video upload from computer is enabled.
 * @param canUploadExternalVideo whether external videos are supported in RTE.
 */
function registerMedia(
  editor: Editor,
  loadingCx: ClassNamesFnWrapper,
  editorCx: ClassNamesFnWrapper,
  formatMessage: FormatMessage,
  onActionForDevice: OnAction,
  onActionForImageUrl: OnAction,
  onActionForVideoUrl: OnAction,
  onActionForImageLink: OnAction,
  onActionForA11y: OnAction,
  isMobile: boolean,
  isImageSerialisationRequired: boolean,
  canUploadImage: boolean,
  canUploadVideo: boolean,
  canUploadExternalVideo: boolean,
  canShowContextToolBarOnMedia: boolean,
  isExternalVideoCookieBannerEnabled?: boolean
): void {
  const alignmentOptions = Object.values(AlignmentOptions).join(' ');

  registerMediaCommon(
    editor,
    loadingCx,
    editorCx,
    formatMessage,
    onActionForA11y,
    onActionForImageLink,
    isImageSerialisationRequired,
    isExternalVideoCookieBannerEnabled
  );
  if (isMobile) {
    registerMediaButtonMobile(
      editor,
      formatMessage,
      onActionForDevice,
      canUploadImage,
      canUploadVideo,
      canUploadExternalVideo
    );
    registerContextToolbar(
      editor,
      `liaImageLink liaImageUnlink ${CustomEditorButton.ACCESSIBILITY}`
    );
  } else {
    registerMediaButtonDesktop(
      editor,
      formatMessage,
      onActionForDevice,
      onActionForImageUrl,
      onActionForVideoUrl,
      canUploadImage,
      canUploadVideo,
      canUploadExternalVideo
    );

    if (canShowContextToolBarOnMedia) {
      registerContextToolbar(
        editor,
        `${Object.values(MediaSize).join(
          ' '
        )} | ${alignmentOptions} | liaImageLink liaImageUnlink ${CustomEditorButton.ACCESSIBILITY}`
      );
    }

    registerResizeEvents(editor, editorCx, isExternalVideoCookieBannerEnabled);
  }
}

/**
 * Handles the `UploadImageMutation`'s response.
 *
 * @param imagePromises Promise returned by the UploadImageMutation call.
 * @param editor Active RTE.
 * @param editorFormatMessage Editor's format message function.
 * @param setImageUploadPromiseRef Setter for ImageUploadPromise ref.
 * @param getImageUploadPromiseRef Getter for ImageUploadPromise ref.
 * @param setTotalMediaFilesRef Setter for TotalMediaFiles ref.
 * @param getTotalMediaFilesRef Getter for TotalMediaFiles ref.
 * @param setMediaFilesProcessedRef Setter for MediaFilesProcessed ref.
 * @param getMediaFilesProcessedRef Getter for MediaFilesProcessed ref.
 * @param setToastPropsRef Setter for ToastPropsArray ref.
 * @param onMediaUploadComplete Callback when image upload completes.
 */
function handleUploadResponse(
  imagePromises: PromiseSettledResult<ImageUploadMarkUpMap>[],
  editor: Editor,
  editorFormatMessage: FormatMessage,
  setImageUploadPromiseRef: (imageUploadPromise: ImageUploadPromise) => void,
  imageUploadPromiseRef: ImageUploadPromise[],
  setTotalMediaFilesRef: (totalMediaFiles: number) => void,
  getTotalMediaFilesRef: () => number,
  setMediaFilesProcessedRef: (mediaFilesProcessed: number) => void,
  getMediaFilesProcessedRef: () => number,
  setToastPropsRef: (toastProps: ToastProps) => void,
  onMediaUploadComplete: () => void,
  setImageDeletedMap: (id: string, flag: boolean) => void,
  getImageDeletedMap: () => Map<string, boolean>
) {
  const mediaObserver = new MutationObserver(function useMutationCallback(mutations) {
    return mutations.forEach(mutation => {
      mutation.removedNodes.forEach(removedNode => {
        const removedElement = removedNode as HTMLElement;
        if (removedElement instanceof Element) {
          const media = removedElement.querySelector('figure');
          if (media && media.dataset.liaImageContainer !== 'undefined') {
            const imageElement = editor
              .getBody()
              .querySelector(`[data-lia-image-container='${media.dataset.liaImageContainer}']`);
            if (imageElement) {
              return;
            }
            setTotalMediaFilesRef(getTotalMediaFilesRef() - 1);
            setImageDeletedMap(media.dataset.liaImageContainer, true);
            if (getMediaFilesProcessedRef() >= getTotalMediaFilesRef()) {
              onMediaUploadComplete();
            }
          }
        }
      });
    });
  });
  mediaObserver.observe(editor.getBody(), { subtree: false, childList: true });
  imageUploadPromiseRef.forEach(({ id, upload }) => {
    return upload.then((response: FetchResult<UploadImageMutation>) => {
      const {
        data: { createUploadedImage },
        errors: errorResponses
      } = response;
      if (createUploadedImage) {
        const { result, errors: uploadErrors } = createUploadedImage;
        if (result) {
          setImageDeletedMap((result as UploadedImageFragment).id, false);
          handleUploadSuccess(
            editor,
            id,
            (result as UploadedImageFragment).id,
            getImageDeletedMap()
          );
          setMediaFilesProcessedRef(getMediaFilesProcessedRef() + 1);
          if (getMediaFilesProcessedRef() >= getTotalMediaFilesRef()) {
            onMediaUploadComplete();
          }
        } else if (uploadErrors) {
          setTotalMediaFilesRef(getTotalMediaFilesRef() - 1);
          uploadErrors.forEach(error => {
            const { __typename, message } = error;
            switch (__typename) {
              case 'UploadLimitReached':
              case 'UserNotRegistered':
              case 'TooManyUploadedImages':
              case 'UploadFailed': {
                setToastPropsRef({
                  toastVariant: ToastVariant.BANNER,
                  alertVariant: ToastAlertVariant.DANGER,
                  title: editorFormatMessage(`error.${__typename}.title`),
                  message,
                  id: `image-${id}-upload-error`
                });
                removeFigure(editor, id);
                break;
              }
              default: {
                removeFigure(editor, id);
              }
            }
          });
          if (getMediaFilesProcessedRef() >= getTotalMediaFilesRef()) {
            onMediaUploadComplete();
          }
        }
      } else if (errorResponses) {
        errorResponses.forEach(error => {
          const { message } = error;
          setToastPropsRef({
            toastVariant: ToastVariant.BANNER,
            alertVariant: ToastAlertVariant.DANGER,
            title: editorFormatMessage('error.Responses.title'),
            message,
            id: `image-${id}-upload-error`
          });
        });
        removeFigure(editor, id);
        setTotalMediaFilesRef(getTotalMediaFilesRef() - 1);
        if (getMediaFilesProcessedRef() >= getTotalMediaFilesRef()) {
          onMediaUploadComplete();
        }
      }
      return response;
    });
  });
  return imagePromises;
}

/**
 * Builds the toast message props for all the image upload errors.
 *
 * @param option File option.
 * @param editorFormatMessage Editor's format message function.
 * @param allowedFileTypesFormatted Allowed file types as a formatted string.
 */
export function buildToastPropsForError(
  option: FileOption,
  editorFormatMessage: FormatMessage,
  allowedFileTypesFormatted: string
): ToastProps {
  const { name: fileName, errors: imageErrors } = option;
  if (imageErrors.length > 0) {
    let errorMessage;
    const [{ id, values }] = imageErrors;
    switch (id) {
      case 'errorTooLarge': {
        errorMessage = editorFormatMessage(`${id}.description`, {
          fileName,
          maxFileSize: values.maxFileSizeFormatted
        });
        break;
      }
      case 'errorBadExtension': {
        errorMessage = editorFormatMessage(`${id}.description`, {
          fileName,
          fileTypes: allowedFileTypesFormatted
        });
        break;
      }
      default: {
        errorMessage = editorFormatMessage(id);
        break;
      }
    }
    return {
      toastVariant: ToastVariant.BANNER,
      alertVariant: ToastAlertVariant.DANGER,
      title: editorFormatMessage(`${id}.title`),
      message: errorMessage,
      id: `${fileName}-${id}`
    };
  }
  return null;
}

/**
 * Async function with calls UploadImageMutation.
 *
 * @param file File to upload.
 * @param associatedEntityType The entity type the image will be associated to.
 * @param createImageUpload UploadImageMutation call.
 * @param imageDeletedMap
 * @param tempId
 */
async function uploadImage(
  file: File,
  associatedEntityType: UploadedImageAssociatedEntityType,
  createImageUpload: (
    options?: MutationFunctionOptions<
      UploadImageMutation,
      Exact<{ file: CreateUploadedImageInput }>
    >
  ) => Promise<FetchResult<UploadImageMutation>>,
  imageDeletedMap: Map<string, boolean>,
  tempId: string
): Promise<FetchResult<UploadImageMutation>> {
  const upload = file as Scalars['Upload']['input'];
  const result = await createImageUpload({
    variables: {
      file: {
        file: upload,
        associationEntityType: associatedEntityType
      }
    },
    context: {
      fetchOptions: {
        useUploadProgress: true,
        onProgress: (event: ProgressEvent) => {
          const isImageDeleted = imageDeletedMap.get(tempId);
          if (!isImageDeleted) {
            const progressPercentage = Math.ceil((event.loaded / event.total) * 100);
            const progressBarElement = getActiveEditor()
              .getBody()
              .querySelector<HTMLElement>('[data-lia-progress-bar]');
            if (progressBarElement) {
              progressBarElement.style.width = `${progressPercentage}%`;
            }
          }
        }
      }
    }
  });

  if (result.errors) {
    // TODO: Handle upload errors
  }

  return result;
}

/**
 * Callback triggered on insertion of images into the RTE.
 *
 * @param acceptedFiles Accepted files from insertion.
 * @param associatedEntityType The entity type the image will be associated to.
 * @param editorCx Editor's class name mapper.
 * @param loadingCx Loading indicator's class name mapper.
 * @param editorFormatMessage Editor's format message function.
 * @param locale Locale of the user.
 * @param imageUploadProperties Image upload admin settings for the community.
 * @param imageExtensionsByEntityType Image extensions and mimetypes allowed for an entity type.
 * @param setImageUploadPromiseRef Setter for ImageUploadPromise ref.
 * @param getImageUploadPromiseRef Getter for ImageUploadPromise ref.
 * @param setTotalMediaFilesRef Setter for TotalMediaFiles ref.
 * @param getTotalMediaFilesRef Getter for TotalMediaFiles ref.
 * @param setMediaFilesProcessedRef Setter for MediaFilesProcessed ref.
 * @param getMediaFilesProcessedRef Getter for MediaFilesProcessed ref.
 * @param setToastPropsRef Setter for ToastPropsArray ref.
 * @param getToastPropsRef Getter for ToastPropsArray ref.
 * @param createImageUpload UploadImageMutation call.
 * @param addToasts Adds toast message to display on error.
 * @param onMediaUploadComplete Callback when image upload completes.
 * @param editor
 * @param setImageDeletedMap
 * @param getImageDeletedMap
 * @param useCopyPaste Flag that defines whther this is used for copy paste.
 * @param fileRejections Rejected files from insertion.
 */
async function uploadImageFilesDefault(
  acceptedFiles: File[],
  associatedEntityType: UploadedImageAssociatedEntityType,
  editorCx: ClassNamesFnWrapper,
  loadingCx: ClassNamesFnWrapper,
  editorFormatMessage: FormatMessage,
  locale: string,
  imageUploadProperties: ImageUploadProperties,
  imageExtensionsByEntityType: AllowedExtensionsAndMimeTypesByEntityType,
  setImageUploadPromiseRef: (imageUploadPromise: ImageUploadPromise | ImageUploadPromise[]) => void,
  getImageUploadPromiseRef: () => ImageUploadPromise[],
  setTotalMediaFilesRef: (totalMediaFiles: number) => void,
  getTotalMediaFilesRef: () => number,
  setMediaFilesProcessedRef: (mediaFilesProcessed: number) => void,
  getMediaFilesProcessedRef: () => number,
  setToastPropsRef: (toastProps: ToastProps) => void,
  getToastPropsRef: () => ToastProps[],
  onMediaUploadComplete: () => void,
  createImageUpload: (
    options?: MutationFunctionOptions<
      UploadImageMutation,
      Exact<{ file: CreateUploadedImageInput }>
    >
  ) => Promise<FetchResult<UploadImageMutation>>,
  addToasts: (toasts: ToastProps[]) => void,
  editor: Editor,
  setImageDeletedMap: (id: string, flag: boolean) => void,
  getImageDeletedMap: () => Map<string, boolean>,
  useCopyPaste: boolean,
  fileRejections?: FileRejection[],
  showCaptionOnImage?: boolean
): Promise<void> {
  const markupPromises: Promise<ImageUploadMarkUpMap>[] = [];
  let filesToUpload = acceptedFiles;
  const { maxFileSize, maxFilesPerUpload } = imageUploadProperties;
  const { allowedFileTypesFormatted, allowedMimeTypesFormatted } = imageExtensionsByEntityType;
  if (acceptedFiles.length > maxFilesPerUpload) {
    filesToUpload = acceptedFiles.slice(0, maxFilesPerUpload);
  }
  const options = filesToUpload.map(file => {
    const { name: fileName, size, type } = file;
    return new FileOption(fileName, size, type);
  });

  const maxFileSizeFormatted = prettyBytes(maxFileSize, { locale });

  const rejectedOptions: FileOption[] = getErrorsFromFileRejections(
    fileRejections,
    maxFileSizeFormatted,
    allowedMimeTypesFormatted
  );
  rejectedOptions?.forEach(option => options.push(option));
  const imageUploadReferences = [];
  options.forEach((option, idx) => {
    if (!option.hasErrors()) {
      const file = acceptedFiles[idx];
      const tempId = useCopyPaste ? getPlaceholderAttributeValue(file.name) : Date.now().toString();
      markupPromises.push(
        createImageHtmlFromFile(
          file,
          tempId,
          editorFormatMessage,
          loadingCx,
          editorCx,
          showCaptionOnImage ?? true
        )
      );

      const promiseRef = {
        id: tempId,
        upload: uploadImage(
          file,
          associatedEntityType,
          createImageUpload,
          getImageDeletedMap(),
          tempId
        )
      };
      imageUploadReferences.push(promiseRef);
      setImageUploadPromiseRef(promiseRef);
    } else {
      setTotalMediaFilesRef(getTotalMediaFilesRef() - 1);
      const toastProps = buildToastPropsForError(
        option,
        editorFormatMessage,
        allowedFileTypesFormatted
      );
      if (toastProps) {
        setToastPropsRef(toastProps);
      }
    }
  });
  if (acceptedFiles.length > maxFilesPerUpload) {
    setToastPropsRef({
      toastVariant: ToastVariant.BANNER,
      alertVariant: ToastAlertVariant.DANGER,
      title: editorFormatMessage('errorTooMany.title'),
      message: editorFormatMessage('errorTooMany.description', { maxFilesPerUpload }),
      id: `errorTooMany-${maxFilesPerUpload}`
    });
  }

  const lastImageTempId =
    getImageUploadPromiseRef().length > 0 &&
    getImageUploadPromiseRef()[getImageUploadPromiseRef().length - 1].id;

  try {
    const markupResult = await Promise.allSettled(markupPromises);
    if (useCopyPaste) {
      insertImagesFromPromisesForCopyPaste(markupResult, editor, null);
    } else {
      insertImagesFromPromises(markupResult, editor, lastImageTempId, null);
    }

    handleUploadResponse(
      markupResult,
      editor,
      editorFormatMessage,
      setImageUploadPromiseRef,
      imageUploadReferences,
      setTotalMediaFilesRef,
      getTotalMediaFilesRef,
      setMediaFilesProcessedRef,
      getMediaFilesProcessedRef,
      setToastPropsRef,
      onMediaUploadComplete,
      setImageDeletedMap,
      getImageDeletedMap
    );
  } catch (error) {
    log.error(error, 'An error occurred while uploading images');
  }

  try {
    await Promise.all(imageUploadReferences.map(uploadPromise => uploadPromise.upload));
    if (getToastPropsRef().length > 0) {
      addToasts(getToastPropsRef());
    }
    // clearing out imageUploadPromises and toastPropsArray once upload is done
    const pendingImageUploadPromises = getImageUploadPromiseRef().filter(uploadPromise => {
      return !imageUploadReferences.some(
        resolvedPromise => resolvedPromise.id === uploadPromise.id
      );
    });
    setImageUploadPromiseRef(pendingImageUploadPromises);
    setToastPropsRef(null);
  } catch (error) {
    log.error(error, 'An error occurred while uploading images');
  }
}

/**
 * Renders the error toast banner when the inserted URL is invalid.
 *
 * @param editorFormatMessage Editor text bundle's formatMessage.
 * @param addToast Adds toast message to display on error.
 * @param title Error title.
 * @param description Error description.
 * @param tempId Temp ID of the image.
 */
function renderImageValidationToast(
  tempId: string,
  editorFormatMessage: FormatMessage,
  description: string,
  addToast: (toast: ToastProps) => void,
  title = ''
): void {
  const toastProps: ToastProps = {
    toastVariant: ToastVariant.BANNER,
    alertVariant: ToastAlertVariant.DANGER,
    title: title || editorFormatMessage('imageUrlValidationError.title'),
    autohide: false,
    message: description || editorFormatMessage('imageUrlValidationError.message'),
    id: `image-${tempId}-upload-error`
  };
  addToast(toastProps);
}

/**
 * Renders the toast messages based on the error.
 *
 * @param error Mutation error.
 * @param tempId temporary image id.
 * @param editorFormatMessage Editor's format message.
 * @param addToast Adds toast message to display on error.
 * @param locale Locale of the user.
 */
export function renderErrorBasedToast(
  error: CreateUploadedImageError,
  tempId: string,
  editorFormatMessage: FormatMessage,
  addToast: (toast: ToastProps) => void,
  locale: string
) {
  const { __typename, message: errorMessage } = error;
  let description;
  const title = editorFormatMessage(`error.${__typename}.title`);
  switch (__typename) {
    case 'UploadTooLarge': {
      const { maximumAllowedUploadSize } = error as UploadTooLarge;
      const maxImageFileSizeFormatted = prettyBytes(maximumAllowedUploadSize, { locale });
      description = editorFormatMessage(`error.${__typename}.description`, {
        maxImageFileSizeFormatted
      });
      break;
    }
    case 'DisallowedImageFormat': {
      const { mimeType, allowedFormats } = error as DisallowedImageFormat;
      description = editorFormatMessage(`error.${__typename}.description`, {
        mimeType,
        allowedFormats
      });
      break;
    }
    default: {
      description = errorMessage;
    }
  }
  renderImageValidationToast(tempId, editorFormatMessage, description, addToast, title);
}

/**
 * Handles the response from the UploadImageMutation call.
 *
 * @param uploadPromise Promise returned by UploadImageMutation call.
 * @param tempId Temp ID of the image.
 * @param editorFormatMessage Editor text bundle's formatMessage.
 * @param addToast Adds toast message to display on error.
 * @param locale Locale of the user.
 * @param onMediaUploadComplete Callback when image upload completes.
 */
function handleUrlUploadResponse(
  uploadPromise: Promise<FetchResult<UploadImageMutation>>,
  tempId: string,
  editorFormatMessage: FormatMessage,
  addToast: (toast: ToastProps) => void,
  locale: string,
  onMediaUploadComplete: () => void,
  editor: Editor,
  setImageDeletedMap: (id: string, flag: boolean) => void,
  getImageDeletedMap: () => Map<string, boolean>
): void {
  uploadPromise
    .then((response: FetchResult<UploadImageMutation>) => {
      const {
        data: {
          createUploadedImage: { result, errors }
        },
        errors: errorResponses
      } = response;
      if (result) {
        const createUploadedImageFragment = result as UploadedImageFragment;
        setImageDeletedMap(createUploadedImageFragment.id, false);
        onImageLoad(
          editor,
          tempId,
          createUploadedImageFragment.id,
          createUploadedImageFragment.url,
          getImageDeletedMap,
          () => {
            onMediaUploadComplete();
          }
        );
      } else if (errors) {
        removeFigure(editor, tempId);
        errors.forEach(error => {
          renderErrorBasedToast(error, tempId, editorFormatMessage, addToast, locale);
        });
        onMediaUploadComplete();
      } else if (errorResponses) {
        removeFigure(editor, tempId);
        errorResponses.forEach(error => {
          const { message: errorMessage } = error;
          renderImageValidationToast(errorMessage, editorFormatMessage, tempId, addToast);
        });
        onMediaUploadComplete();
      }
      return response;
    })
    .catch(error => log.error(error, 'Error handling image upload response'));
}

/**
 * Creates the image markup and inserts it into the RTE. Also adds the onLoad event to remove the loading indicator.
 *
 * @param tempId temporary image id.
 * @param previewUrl external URL used as the preview URL till image upload is complete.
 * @param editorCx Editor's class name mapper.
 * @param loadingCx Loading indicator's class name mapper.
 * @param editorFormatMessage Editor text bundle's formatMessage.
 * @param editor
 */
function insertImage(
  tempId: string,
  previewUrl: string,
  editorCx: ClassNamesFnWrapper,
  loadingCx: ClassNamesFnWrapper,
  editorFormatMessage: FormatMessage,
  editor: Editor,
  showCaptionOnImage?: boolean
): void {
  const markup = createImageTemplate(
    { id: tempId, src: previewUrl },
    editorFormatMessage,
    loadingCx,
    editorCx,
    true,
    undefined,
    undefined,
    showCaptionOnImage ?? true
  ).html;
  insertImageMarkup(editor, markup, tempId);
}

/**
 * Calls UploadImageMutation and returns a promise.
 *
 * @param url external image url.
 * @param associatedEntityType The entity type the image will be associated to.
 * @param createImageUpload UploadImageMutation call.
 */
async function uploadImagePromise(
  url: string,
  associatedEntityType: UploadedImageAssociatedEntityType,
  createImageUpload: (
    options?: MutationFunctionOptions<
      UploadImageMutation,
      Exact<{ file: CreateUploadedImageInput }>
    >
  ) => Promise<FetchResult<UploadImageMutation>>
): Promise<FetchResult<UploadImageMutation>> {
  return await createImageUpload({
    variables: {
      file: {
        imageUrl: url,
        associationEntityType: associatedEntityType
      }
    }
  });
}

/**
 * Callback triggered on insertion of an image into the RTE via URL upload.
 *
 * @param url Image's external site url.
 * @param associatedEntityType The entity type the image will be associated to
 * @param editorCx Editor's class name mapper.
 * @param loadingCx Loading indicator's class name mapper.
 * @param editorFormatMessage Editor's format message function.
 * @param createImageUpload UploadImageMutation call.
 * @param addToast Adds toast message to display on error.
 * @param locale locale of the user.
 * @param onMediaUploadComplete Callback when image upload completes.
 * @param editor
 * @param setImageDeletedMap
 * @param getImageDeletedMap
 */
function uploadImageUrlDefault(
  url: string,
  associatedEntityType: UploadedImageAssociatedEntityType,
  editorCx: ClassNamesFnWrapper,
  loadingCx: ClassNamesFnWrapper,
  editorFormatMessage: FormatMessage,
  createImageUpload: (
    options?: MutationFunctionOptions<
      UploadImageMutation,
      Exact<{ file: CreateUploadedImageInput }>
    >
  ) => Promise<FetchResult<UploadImageMutation>>,
  addToast: (toast: ToastProps) => void,
  locale: string,
  onMediaUploadComplete: () => void,
  editor: Editor,
  setImageDeletedMap: (id: string, flag: boolean) => void,
  getImageDeletedMap: () => Map<string, boolean>,
  showCaptionOnImage?: boolean
) {
  const uploadPromise = uploadImagePromise(url, associatedEntityType, createImageUpload);
  const tempId = Date.now().toString();
  insertImage(
    tempId,
    url,
    editorCx,
    loadingCx,
    editorFormatMessage,
    editor,
    showCaptionOnImage ?? true
  );
  handleUrlUploadResponse(
    uploadPromise,
    tempId,
    editorFormatMessage,
    addToast,
    locale,
    onMediaUploadComplete,
    editor,
    setImageDeletedMap,
    getImageDeletedMap
  );
}

export {
  registerMedia,
  getImgElement,
  insertImageMarkup,
  insertImagesFromPromises,
  insertImagesFromPromisesForCopyPaste,
  handleUploadSuccess,
  onImageLoad,
  removeFigure,
  createImageTemplate,
  createImageHtmlFromFile,
  isImageFigure,
  getImageAlignFormat,
  getAnchorWithinFigure,
  removeImageSourceAndLoadingAttributes,
  handleAltTextUpdate,
  getLoadingIndicator,
  getMediaClass,
  setLayout,
  addImageNameData,
  getImageWrapperById,
  uploadImageFilesDefault,
  uploadImageUrlDefault,
  isFigCaption,
  isMediaWrapperElement,
  getContainerElementWidth
};
